import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/internal/Subject';

@Injectable({ providedIn: 'root' })

export class MapService {
    private jobToShowObs$ = new Subject<any>();

    selectedTheme: any;
    themes: any[];

    constructor() {}


    listenForJobChange() {
        return this.jobToShowObs$;
    }

    announceJobChange(_job) {
        this.jobToShowObs$.next(_job);
    }


    getLeadAddress(_lead, _fullZipDetails) {
        let leadAddress = '';
        let missingAddressParts = false;
    
        if (_lead.current_address) {
          // console.log('Current Address: ', _lead.current_address);
    
          if (_lead.current_address.street && _lead.current_address.street.length) {
            leadAddress += _lead.current_address.street + ', ';
          } else {
            missingAddressParts = true;
          }
    
          if (_lead.current_address.city && _lead.current_address.city.length) {
            leadAddress += _lead.current_address.city + ', ';
          } else {
            missingAddressParts = true;
          }
    
          if (_lead.current_address.state && _lead.current_address.state.length) {
            leadAddress += _lead.current_address.state + ' ';
          } else {
            missingAddressParts = true;
          }
    
          if (_lead.current_address.zip && _lead.current_address.zip.length) {
            leadAddress += _lead.current_address.zip;
          } else {
            missingAddressParts = true;
          }
        } else {
          missingAddressParts = true;
        }
    
        if (missingAddressParts && _fullZipDetails && _fullZipDetails.city) {
          leadAddress = _fullZipDetails.city + ', ' + _fullZipDetails.state + ' ' + _fullZipDetails.zip_code;
        }
    
        return leadAddress;
    }


    getAddress(_address, _fullZipDetails) {
      let leadAddress = '';
      let missingAddressParts = false;
  
      if (_address) {
        // console.log('Current Address: ', _address);
  
        if (_address.street && _address.street.length) {
          leadAddress += _address.street + ', ';
        } else {
          missingAddressParts = true;
        }
  
        if (_address.city && _address.city.length) {
          leadAddress += _address.city + ', ';
        } else {
          missingAddressParts = true;
        }
  
        if (_address.state && _address.state.length) {
          leadAddress += _address.state + ' ';
        } else {
          missingAddressParts = true;
        }
  
        if (_address.zip && _address.zip.length) {
          leadAddress += _address.zip;
        } else {
          missingAddressParts = true;
        }
      } else {
        missingAddressParts = true;
      }
  
      if (missingAddressParts && _fullZipDetails && _fullZipDetails.city) {
        leadAddress = _fullZipDetails.city + ', ' + _fullZipDetails.state + ' ' + _fullZipDetails.zip_code;
      }
  
      return leadAddress;
  }
}
