import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { cacheable } from '@app/utils/rxjs-functions';

import { ExpiringCache } from '@app/models/expiringCache';
import { SharedUtilsService } from '@app/services/shared-utils.service';

export interface InteractiveDocumentsApi {
  items: any[];
  total_count: number;
}

export class PdfInput {
  type: string;
  field;
  assigned_to;
  recipient_id;
  top: number;
  left: number;
  width: number;
  height: number;
  systemFieldName: string;
  options;

  constructor(_type: string, _top: number, _left: number, _width: number, _field) {
    this.type = _type;
    this.top = _top;
    this.left = _left;
    this.width = _width;
    this.field = _field;
    this.height = 25;
  }
}

@Injectable({ providedIn: 'root' })
export class InteractiveDocumentsService {
  private currentDocument;
  private API_URL = '/api/interactive-documents/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  documentsStorage: ExpiringCache = new ExpiringCache();

  public currentSearch = {
    params: null,
    results: null
  };

  constructor(
    private httpClient: HttpClient,
    private _sharedUtilsService: SharedUtilsService
  ) { }


  setCurrent(_document): void {
    this.currentDocument = _document;
  }


  getCurrent() {
    return this.currentDocument;
  }


  create(_document): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_document), this.headerOptions);
  }


  generateImagesFromDocument(_docDetails): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'generate-images-from-url', JSON.stringify(_docDetails), this.headerOptions);
  }


  getCached(): Observable<any[]> {
    // smarter than just going to api every time. Getting them all, so just cache for use in dropdowns all over
    if (this._sharedUtilsService.isStorageValid(this.documentsStorage)) {
      // console.log('Saved an API call for locations. Retreived from cache.');
      return this.documentsStorage.itemCache;
    }

    this.documentsStorage = this._sharedUtilsService.updateStorageExpiration(this.documentsStorage);
    // console.log('Set locations storage to: ', this.locationsStorage);
    return this.documentsStorage.itemCache = cacheable<any>(this.httpClient.get<any[]>(this.API_URL));
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<Document[]> {
    return this.httpClient.put<Document[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_document): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _document._id, JSON.stringify(_document), this.headerOptions);
  }


  updateFavorites(_document): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _document._id, JSON.stringify(_document), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getDocument(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  selectAllSearch(_moduleOptions): Observable<Document[]> {
    // searchTerms['selectAll'] = true;
    _moduleOptions.searchTerms['selectAll'] = true;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<Document[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions);
  }


  selectNumberOfRecordsSearch(_moduleOptions): Observable<any> {
    _moduleOptions.searchTerms['selectAll'] = false;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions);
  }


  getDataSource(_moduleOptions, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<InteractiveDocumentsApi> {
    _moduleOptions.searchTerms['selectAll'] = false;
    _moduleOptions.searchTerms['selectNumberOfRecords'] = false;

    _moduleOptions.searchTerms.sortField = sortField;
    _moduleOptions.searchTerms.sortOrder = sortDirection;
    _moduleOptions.searchTerms.pageNumber = pageNumber;
    _moduleOptions.searchTerms.pageSize = pageSize; 

    return this.httpClient.post<InteractiveDocumentsApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(_moduleOptions), this.headerOptions); 
  }
}