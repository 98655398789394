import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { cacheable } from '../utils/rxjs-functions';

import { ExpiringCache } from '../models/expiringCache';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { MatDialog } from '@angular/material/dialog';

import { DuplicationMergerDialogComponent } from '@app/modules/module-tools/duplication-merger-dialog/duplication-merger-dialog.component';
import { DupAccountDialogComponent } from '@app/modules/accounts/dup-account-dialog/dup-account-dialog.component';

import { TableService } from './table.service';
import { SharedUtilsService } from './shared-utils.service';
import { SsModuleService } from './ss-module.service';
import { GenericModuleService } from './generic-module.service';
import { MergeService } from './merge.service';
import { Activity } from '@app/models/activity';
import { ActivityService } from './activity.service';

export interface AccountApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
  private currentAccount;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/accounts/';

  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  accountsStorage: ExpiringCache = new ExpiringCache();

  constructor(
    private _dialog: MatDialog,
    private httpClient: HttpClient,
    private _activityService: ActivityService,
    private _tableService: TableService,
    private _moduleService: SsModuleService,
    private _mergeService: MergeService,
    private _genericService: GenericModuleService,
    private _sharedUtilsService: SharedUtilsService
  ) { }


  setCurrent(_account): void {
    this.currentAccount = _account;
  }


  getCurrent() {
    return this.currentAccount;
  }


  create(_account): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_account), this.headerOptions);
  }


  getCached(): Observable<any[]> {
    // smarter than just going to api every time. Getting them all, so just cache for use in dropdowns all over
    if (this._sharedUtilsService.isStorageValid(this.accountsStorage)) {
      // console.log('Saved an API call for locations. Retreived from cache.');
      return this.accountsStorage.itemCache;
    }

    this.accountsStorage = this._sharedUtilsService.updateStorageExpiration(this.accountsStorage);
    // console.log('Set locations storage to: ', this.locationsStorage);
    return this.accountsStorage.itemCache = cacheable<any>(this.httpClient.get<any[]>(this.API_URL));
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<any[]> {
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_account): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _account._id, JSON.stringify(_account), this.headerOptions);
  }


  updateFavorites(_account): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _account._id, JSON.stringify(_account), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  placementOppsSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'placement-opps-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getAccount(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  dupCheck(_account): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'dup-check', JSON.stringify(_account), this.headerOptions);
  }


  selectAllSearch(searchOptions): Observable<any[]> {
    searchOptions['selectAll'] = true;
    searchOptions['selectNumberOfRecords'] = false;
    searchOptions.searchTerms['idsOnly'] = false; // Incase it is set on searchTerms.
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchOptions), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    searchParams['idsOnly'] = false; // For only returning basic lightweight version of results if we don't need all fields.
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<AccountApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;
    searchParams['idsOnly'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<AccountApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }


  confirmDupCreation(_duplicatesDetected): Promise<boolean> {
    return new Promise(async (resolve) => {
      const dialogRef = this._dialog.open(DupAccountDialogComponent, {});

      const instance = dialogRef.componentInstance;
      instance.items = _duplicatesDetected;

      const _createWithMergeRes = await lastValueFrom(dialogRef.afterClosed());
      resolve(_createWithMergeRes);
    });
  }


  showMergeDialog(_leadModule) {
    return new Promise(async (resolve) => {
      const accountsModule = this._moduleService.getFullModuleByName('accounts');
      const dialogRef = this._dialog.open(DuplicationMergerDialogComponent, { disableClose: true, panelClass: 'user-search' });

      const instance = dialogRef.componentInstance;
      instance.modulePassedIn = accountsModule;

      const _mergeResults = await lastValueFrom(dialogRef.afterClosed());
      const _mergedAccount = await this.processMerge(_mergeResults, accountsModule);

      resolve(_mergedAccount);
    });
  }


  createActivityRecord(_account) {
    return new Promise(async(resolve, reject) => {
      const activity = new Activity();
      activity.activityType = 'Merge';
      activity.relatedRecord = {recordType: 'accounts', recordId: _account._id};
      activity.relatedRecords = [{recordType: 'accounts', recordId: _account._id}];
      // activity.relatedModule = {moduleType: 'message', moduleRecordId: _message._id};
      activity.created_by = (_account.created_by && _account.created_by._id) ? _account.created_by._id : _account.created_by;
      activity.modified_by = (_account.modified_by && _account.modified_by._id) ? _account.modified_by._id : _account.modified_by;
      activity.assigned_to = (_account.assigned_to && _account.assigned_to._id) ? _account.assigned_to._id : _account.assigned_to;

      const savedActivity = await lastValueFrom(this._activityService.create(activity));

      resolve(savedActivity);
    });
  }


  processMerge(_mergeRequest, _module) {
    return new Promise(async (resolve) => {
      let mergedAccount = null;

      if (_mergeRequest) {
        mergedAccount = await lastValueFrom(this.update(_mergeRequest.primaryRecord));
        // if email address was updated, add that as well

        // create merge activity record here...
        await this.createActivityRecord(_mergeRequest.primaryRecord);

        _mergeRequest.recordsToDelete.forEach(async(_rd) => {
          const _mergeCheck$ = await this._mergeService.mergeRequest('accounts', _rd._id, _mergeRequest.primaryRecord._id);
          await lastValueFrom(_mergeCheck$);
        });

        // delete the deletedRecords
        const deleteReq$ = await this._genericService.markDeletedByIdArray({ name: 'accounts', schema: _module.customSchema }, _mergeRequest.recordsToDelete);
        await lastValueFrom(deleteReq$);

        this._sharedUtilsService.showNotification("Accounts have been merged and duplicates removed");
      }

      resolve(mergedAccount);
    });
  }


  sharedAccountCreated(_modulePassedIn, _newAccount): Promise<any> {
    return new Promise(async (resolve) => {
      try {
        // console.log('NEW Account to create from: ', _newAccount);
        this._sharedUtilsService.showNotification("Checking for duplicate accounts");

        // check for dups here...
        const duplicateRecords = await lastValueFrom(this.dupCheck(_newAccount));
        // console.log('Dup Record Check: ', duplicateRecords);

        let proceedWithCreation = true;

        this._sharedUtilsService.showNotification("Saving Account");

        const _newAccountCreated = await lastValueFrom(this.create(_newAccount));
        // console.log('New Account Created: ', _newAccountCreated);

        if (duplicateRecords && duplicateRecords.length) {
          const proceedAndDoMerge = await this.confirmDupCreation(duplicateRecords);
          // console.log('proceedAndDoMerge: ', proceedAndDoMerge);

          if (proceedAndDoMerge == false) {
            // just create the record. No merge check
            proceedWithCreation = true;
          } else if (proceedAndDoMerge == null) {
            // console.log('You said to abandon creation. Good move.');
            proceedWithCreation = false;

            resolve(null);
          } else {
            const duplicateRecordsWithPrimary = [_newAccountCreated];
            duplicateRecords.forEach(_r => duplicateRecordsWithPrimary.push(_r));

            this._tableService.setResults(duplicateRecordsWithPrimary);

            // console.log('Selected Rows: ', this._tableService.selectedRows.selected);

            await this.showMergeDialog(_modulePassedIn);
          }
        }

        _newAccount._id = _newAccountCreated['_id'];

        // console.log('Matching complete. Should redirect to new lead?');
        resolve(_newAccountCreated);
      } catch (_err) {
        console.log('Error creating account: ', _err);
        resolve(null);
      }
    });
  }


  sharedAccountUpdated(_modulePassedIn, _updatedAccount): Promise<any> {
    return new Promise(async (resolve) => {
      try {
        // console.log('Updated Account to create from: ', _updatedAccount);
        const uneditedAccount = { ..._updatedAccount };

        // check for dups here...
        this._sharedUtilsService.showNotification("Checking for duplicate accounts");

        const allDuplicateRecords = await lastValueFrom(this.dupCheck(_updatedAccount));
        const duplicateRecords = allDuplicateRecords.filter(_r => _r?._id != _updatedAccount?._id);

        let proceedWithCreation = true;

        if (duplicateRecords && duplicateRecords.length) {
          const proceedAndDoMerge = await this.confirmDupCreation(duplicateRecords);
          // console.log('proceedAndDoMerge: ', proceedAndDoMerge);

          if (proceedAndDoMerge == false) {
            // just create the record. No merge check
            proceedWithCreation = true;
          } else if (proceedAndDoMerge == null) {
            // console.log('You said to abandon creation. Good move.');
            proceedWithCreation = false;
            resolve(null);
          } else {
            const duplicateRecordsWithPrimary = [];

            // if it doesn't exist, push it
            if (!allDuplicateRecords.find(_r => _r?._id === _updatedAccount?._id)) {
              allDuplicateRecords.push(_updatedAccount);
            }

            allDuplicateRecords.sort((a, b) => new Date(b['updatedAt']).getTime() - new Date(a['updatedAt']).getTime()).forEach(_r => duplicateRecordsWithPrimary.push(_r));
            this._tableService.resetSelectedRows();
            this._tableService.setResults(duplicateRecordsWithPrimary);
            _updatedAccount = await this.showMergeDialog(_modulePassedIn);
          }
        }

        if (!_updatedAccount) _updatedAccount = uneditedAccount;
        // console.log('_updatedAccount: ', _updatedAccount);

        let _newUpdatedLead = { ..._updatedAccount };

        if (proceedWithCreation) {
          this._sharedUtilsService.showNotification("Saving updates");
          // console.log('No merge was performed. Do update here.');
          _newUpdatedLead = await lastValueFrom(this.update(_updatedAccount));
        }

        resolve(_newUpdatedLead);
      } catch (_err) {
        console.log('Error updating account: ', _err);
        resolve(null);
      }
    });
  }
}