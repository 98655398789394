import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { AuditLog } from '../models/auditLog';
import { AuthService } from './auth.service';
import { SharedUtilsService } from './shared-utils.service';
import { SsModule } from '@app/models';

export interface AuditApi {
  items: AuditLog[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class AuditLogService {
  private currentAuditItemBeingViewed: AuditLog;

  private API_URL = '/api/audit-log/';
  headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  fieldsToIgnore = ['email', 'previous_status', 'interested_location_zips', 'comment_list', 'created_by', 'updated_by', 'createdAt', 'updatedAt', 'monthly_hours', 'school_calendar_start_date', 'school_calendar_end_date', 'job_rating'];
  fieldTypesToIgnore = ['MessageListField']; // Used in getChanges function last else if condition to exclude checking these field types.

  public itemToAudit;

  constructor(private httpClient: HttpClient, private _authService: AuthService, private _sharedUtilService: SharedUtilsService) { }


  setCurrentAuditItemBeingViewed(_auditItem: AuditLog): void {
    this.currentAuditItemBeingViewed = _auditItem;
  }


  getCurrentAuditItemBeingViewed(): AuditLog {
    return this.currentAuditItemBeingViewed;
  }


  getAll(): Observable<AuditLog[]> {
    return this.httpClient.get<AuditLog[]>(this.API_URL);
  }


  getActivityById(id: string): Observable<AuditLog> {
    return this.httpClient.get<AuditLog>(this.API_URL + id);
  }


  getChangesForItem(itemToAudit): Observable<AuditLog[]> {
    return this.httpClient.get<AuditLog[]>(this.API_URL + 'get-record-changes-by-module/' + itemToAudit.module + '/' + itemToAudit.record_id);
  }


  async createViewEntry(recordViewed, moduleType) {
    // console.log('Creating change entries for module: ', moduleType);

    const currentUser = this._authService.getCurrentlyLoggedInUser();
    // console.log('Fields changed: ', fieldsChanged);

    let moduleName = this._sharedUtilService.getNameFromModule(moduleType, recordViewed);;
    // console.log('Module name: ', moduleName);

    const auditEntry = new AuditLog();
    auditEntry.changed_by = currentUser._id;
    auditEntry.module = moduleType;
    auditEntry.record_id = recordViewed._id;
    auditEntry.record_name = moduleName;
    auditEntry.field = 'record';
    auditEntry.new_value = 'Viewed';

    await this.create(auditEntry).toPromise();
  }


  async createChangeEntry(uneditedObject, changedObject, _module) {
    // console.log('Creating change entries for module: ', moduleType);

    const _moduleName = _module.name;

    const currentUser = this._authService.getCurrentlyLoggedInUser();
    const fieldsChanged = this.getChanges(uneditedObject, changedObject, _module);
    const moduleName = this._sharedUtilService.getNameFromModule(_moduleName, changedObject);

    // console.log('Fields changed: ', fieldsChanged);

    fieldsChanged.filter(_f => !this.fieldsToIgnore.includes(_f.field)).forEach(async(_fieldChanged) => {
      const _fullField = _module.fields.find(_f => _f.fieldName === _fieldChanged.field);

      // Create an audit entry for this field
      // console.log('This field has changed: ', _fullField);
      // if ((_fieldChanged.previous_value && _fieldChanged.previous_value.length) || (_fieldChanged.new_value && _fieldChanged.new_value.length))
      const auditEntry = new AuditLog();
      auditEntry.changed_by = currentUser._id;
      auditEntry.module = _moduleName.toLowerCase();
      auditEntry.fullModule = _module._id;
      auditEntry.changeSource = 'direct record';
      auditEntry.fullField = _fullField;
      auditEntry.record_id = changedObject._id;
      auditEntry.record_name = moduleName;
      auditEntry.field = _fieldChanged.field;
      auditEntry.previous_value = _fieldChanged.previous_value;
      auditEntry.new_value = _fieldChanged.new_value;
      // console.log("auditEntry:", auditEntry)

      await this.create(auditEntry).toPromise();
    });
  }


  createChangeLogEntries(_module: SsModule, _recordsToUpdate, _fieldsChanged, _currentUserId): Observable<any> {
    const changeLogReq = {
      module: _module,
      records: _recordsToUpdate,
      fieldsChanged: _fieldsChanged,
      userId: _currentUserId
    };

    return this.httpClient.post<any>(this.API_URL + 'create-change-log-entries', JSON.stringify(changeLogReq), this.headerOptions);
  }


  create(_auditItem: AuditLog): Observable<AuditLog> {
    return this.httpClient.post<AuditLog>(this.API_URL, JSON.stringify(_auditItem), this.headerOptions);
  }


  update(_auditItem: AuditLog): Observable<AuditLog> {
    return this.httpClient.put<AuditLog>(this.API_URL + _auditItem._id, JSON.stringify(_auditItem), this.headerOptions);
  }


  search(searchTerms): Observable<AuditLog[]> {
    return this.httpClient.post<AuditLog[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getChanges(original, changed, _module, _objectToCheck = null) {
    const changesDetected = [];

    _module.fields.forEach(_f => {
      const fieldName = _f.fieldName;
      const fieldType = _f.type.inputType;

      let originalVal = (original && original[fieldName] != undefined && original[fieldName] != 'undefined') ? original[fieldName] : null;
      let changedVal = (changed[fieldName] != undefined && changed[fieldName] != 'undefined') ? changed[fieldName] : null;

      // console.log(`${original[fieldName]}: `, originalVal);
      // console.log(`${changed[fieldName]}: `, changedVal);

      if (['FlexRelate', 'Module', 'Relate'].includes(fieldType)) {
        const ov = originalVal && originalVal._id ? originalVal._id : originalVal;
        const nv = changedVal && changedVal._id ? changedVal._id : changedVal;

        // console.log('Old Value: ', ov);
        // console.log('New Value: ', nv);

        if (ov != nv) {
          const oldRecordTrimmed = originalVal && originalVal._id ? {_id: originalVal._id, name: originalVal.name} : originalVal;
          const newRecordTrimmed = changedVal && changedVal._id ? {_id: changedVal._id, name: changedVal.name} : changedVal;

          changesDetected.push({field: fieldName, previous_value: oldRecordTrimmed, new_value: newRecordTrimmed});
        }
      } else if (['MultiFlexRelate', 'MultiModule', 'MultiRelate'].includes(fieldType)) {
        if(JSON.stringify(originalVal) !== JSON.stringify(changedVal)) {
          // console.log(`Field change detected for ${fieldName}: `, _f);

          // console.log('Original Value: ', originalVal);
          // console.log('Changed Value: ', changedVal);

          const oldTrimmedArray = originalVal && originalVal.length ? originalVal.map(_val => _val._id ? {_id: _val._id, name: _val.name} : _val) : originalVal;
          const newTrimmedArray = changedVal && changedVal.length ? changedVal.map(_val => _val._id ? {_id: _val._id, name: _val.name} : _val) : changedVal;

          changesDetected.push({field: fieldName, previous_value: oldTrimmedArray, new_value: newTrimmedArray});
        }
      } else if(!this.fieldTypesToIgnore.includes(fieldType) && JSON.stringify(originalVal) !== JSON.stringify(changedVal)) {
        // Not doing this check for any fields with fieldType included in fieldTypesToIgnore array.

        // console.log(`Field change detected for ${fieldName}: `, _f);

        // console.log('Original Value: ', originalVal);
        // console.log('Changed Value: ', changedVal);

        changesDetected.push({field: fieldName, previous_value: originalVal, new_value: changedVal});
      }
    });

    // console.log('changesDetected: ', changesDetected);

    return changesDetected;
  }


  selectAllSearch(searchTerms: AuditLog): Observable<AuditLog[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<AuditLog[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getAuditCustomDatasource(searchParams, sortField = 'date_created', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<AuditApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<AuditApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}