<div class="colored-bg-wrapper bg">

    <div class="colored-bg-container">
        <div class="colored-bg-content">

            <form class="login-form full-width" novalidate>
                <div class="header-block">
                    <h2 class="formTitle">{{ isLocker ? 'Locker' : 'yeehro' }}</h2>
                </div>
    
                <div *ngIf="errorMessage">
                    <p style="color: red;">{{ errorMessage }}</p>
                </div>    
                
                <div class="loading-shaded-spinner" *ngIf="isLoading">
                    <mat-spinner></mat-spinner>
                </div> 
    
                <form novalidate class="inner-form" (ngSubmit)="login()" *ngIf="!isLoading">
                    <div class="flex-col">

                        <div class="form-group">
                            <label for="emailInput" class="align-left">Email</label>
                            <input type="text" [(ngModel)]="user.email" name="email" class="form-control" placeholder="">
                        </div>
                        <div class="form-group">
                            <label for="passwordInput" class="align-left">Password</label>
                            <input type="password" [(ngModel)]="user.password" name="password" class="form-control" placeholder="">
                        </div>

                    </div>

                    <div class="remember-forgot-wrapper">
                        <div class="remember-me"> 
                            <mat-checkbox color="primary" class="example-margin" [(ngModel)]="rememberMe" name="rememberMe">Remember Me</mat-checkbox>          
                        </div>    
                        
                        <div class="forgot-password">
                            <a class="forgot-password-link" [routerLink]="['/forgot-password']">Forgot your password?</a>
                        </div>                        
                    </div>               
    
                    <div class="submit-wrapper">
                        <button type="submit" [disabled]="!user.email || !user.password" class="btn btn-primary btn-lg btn-block signin-btn">Sign In</button>
                    </div>
                </form>
            </form>
        </div>
    </div>

    <div class="floating-logo">
        <img src="/assets/images/yeeHRologo.png" width="200">
    </div>
</div>