import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { Notification } from '@app/models/notification';
import { AuthService } from './auth.service';

export interface NotificationApi {
  items: Notification[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private currentBeingViewed: Notification;

  public messageTypes = ['MINT', 'SMS', 'Voiceshot'];

  private API_URL = '/api/notifications/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private httpClient: HttpClient, private _authService: AuthService) { }

  setCurrentBeingViewed(_notification: Notification): void {
    this.currentBeingViewed = _notification;
  }

  getCurrentBeingViewed(): Notification {
    return this.currentBeingViewed;
  }    


  create(_notification: Notification): Observable<Notification> {
    _notification.sentBy = this._authService.getCurrentlyLoggedInUser();
    return this.httpClient.post<Notification>(this.API_URL, JSON.stringify(_notification), this.headerOptions);
  }


  get(_id: string): Observable<Notification> {
    return this.httpClient.get<Notification>(this.API_URL + _id);
  }   


  processBlastSms(smsNotificationDetails): Observable<Notification> {
    smsNotificationDetails.sentBy = this._authService.getCurrentlyLoggedInUser()._id;
    return this.httpClient.post<Notification>(this.API_URL + 'process-blast-sms', JSON.stringify(smsNotificationDetails), this.headerOptions);
  }


  sendSingleSms(smsDetails): Observable<any> {
    return this.httpClient.post<Notification>(this.API_URL + 'send-single-sms', JSON.stringify(smsDetails), this.headerOptions);
  }  


  processBlastVoiceshot(voiceshotDetails): Observable<Notification> {
    voiceshotDetails.sentBy = this._authService.getCurrentlyLoggedInUser()._id;
    return this.httpClient.post<Notification>(this.API_URL + 'process-blast-voiceshot', JSON.stringify(voiceshotDetails), this.headerOptions);
  }


  getNotificationsByAbsence(absenceId: string): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(this.API_URL + 'absence/' + absenceId);
  }


  getNotificationsByProvider(providerId: string): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(this.API_URL + 'provider/' + providerId);
  }


  search(searchTerms): Observable<Notification[]> {
    searchTerms['selectAll'] = false;
    return this.httpClient.post<Notification[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  } 


  getCustomDatasource(searchParams, sortField = 'dateSent', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<NotificationApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<NotificationApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }  

}
