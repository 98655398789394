import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Socket } from 'ngx-socket-io';

import { Observable } from 'rxjs/internal/Observable';

import { SystemResetConfirmationDialogComponent } from '@app/modules/dialogs/system-reset-confirmation-dialog/system-reset-confirmation-dialog';

import { AdminSetting } from '../models/adminSetting';
import { SharedUtilsService } from './shared-utils.service';

@Injectable({ providedIn: 'root' })
export class AdminSettingsService {
  private API_URL = '/api/admin-settings/';
  private EVENT_URL = '/events/';

  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private savedAdminSettings: AdminSetting[];

  public adminSections = [
    {
      label: 'Users',
      iconType: 'image',
      icon: 'icons8-staff-50.png',
      desc: 'Manage users, set roles, create teams and reset passwords to access Yeehro',
      sections: [
        {
          label: 'User Management',
          iconType: 'image',
          icon: 'icons8-join-50.svg',
          route: 'user-management',
          desc: 'Add, create, edit, and remove users'
        },
        {
          label: 'Role Management',
          iconType: 'image',
          icon: 'icons8-work-50.png',
          route: 'role-management',
          desc: 'Manage user roles. Give access or take access away from modules'
        },
        {
          label: 'Team Management',
          iconType: 'image',
          icon: 'icons8-group-task-50.png',
          route: 'team-management',
          desc: 'Define and manage teams. Add and remove users from them'
        },
        {
          label: 'Password Management',
          iconType: 'image',
          icon: 'icons8-walkie-talkie-50.png',
          route: 'password-management',
          desc: 'Set or change users passwords'
        }
      ]
    },
    {
      label: 'Reports',
      iconType: 'image',
      icon: 'icons8-statistics-50.svg',
      sections: [
        {
          label: 'Available Reports',
          iconType: 'image',
          icon: 'icons8-slice-50.svg',
          route: 'kpi-reports'
        }
      ]
    },
    {
      label: 'Settings',
      iconType: 'image',
      icon: 'icons8-settings-50.svg',
      sections: [
        {
          label: 'General Settings',
          iconType: 'image',
          icon: 'icons8-settings-50.svg',
          route: 'settings/general-settings',
          desc: 'Configure system-wide settings'
        },
        {
          label: 'Job Rating Parameters',
          iconType: 'image',
          icon: 'icons8-star-filled-50.svg',
          route: 'settings/job-rating-settings',
          desc: 'Configure job rating parameters'
        },
        {
          label: 'Scheduler',
          iconType: 'image',
          icon: 'icons8-timer-50.svg',
          route: 'scheduler/list',
          desc: 'View Scheduled Jobs'
        },
        {
          label: 'KPI Parameters',
          iconType: 'image',
          icon: 'icons8-stocks-growth-50.svg',
          route: 'settings/kpi-settings',
          desc: 'Configure Key Performance Indicator parameters'
        },
        {
          label: 'Job Spread Percentages',
          iconType: 'image',
          icon: 'icons8-rent-50.png',
          route: 'settings/job-spread-percentages',
          desc: 'Adjust Job Spread Percentages'
        },
        {
          label: 'SMS Campaign Numbers',
          iconType: 'image',
          icon: 'icons8-language-skill-50.png',
          route: 'settings/sms-campaign-numbers',
          desc: 'Manage the phone numbers used for sending SMS Campaigns'
        },
        {
          label: 'Indeed Campaign Boost by Profession Limits',
          iconType: 'image',
          icon: 'icons8-language-skill-50.png',
          route: 'settings/indeed-boost-profession-counts',
          desc: 'Manage the limits for boosting by professions.'
        }      
      ],
    },
    {
      label: 'Dropdowns',
      iconType: 'image',
      icon: 'icons8-management-50.png',
      sections: [
        {
          label: 'Dropdown Management',
          iconType: 'image',
          icon: 'icons8-management-50.png',
          route: 'dropdown-editor'
        },
        {
          label: 'Document Types and Expiration Dates',
          iconType: 'image',
          icon: 'icons8-management-50.png',
          route: 'document-type-expirations'
        }
      ]
    },
    {
      label: 'Developer Tools',
      iconType: 'image',
      icon: 'icons8-cards-50.png',
      sections: [
        {
          label: 'Studio',
          iconType: 'image',
          icon: 'icons8-drawing-50.png',
          route: 'studio',
          desc: 'Customize module fields, layouts and relationships'
        },
        {
          label: 'Module Builder',
          iconType: 'image',
          icon: 'icons8-digger-50.png',
          route: 'module-builder',
          desc: 'Build new modules to expand the functionality of YeeHro'
        },
        {
          label: 'Workflow Management',
          iconType: 'image',
          icon: 'icons8-workflow-50.svg',
          route: 'workflow-management',
          desc: 'Manage workflows'
        },
        {
          label: 'Sidebar Buckets',
          iconType: 'image',
          icon: 'icons8-news-50.svg',
          route: 'sidebar-buckets',
          desc: 'Choose which modules are displayed in the navigation bar and their icons'
        },
        {
          label: 'Admin Notes',
          iconType: 'image',
          icon: 'icons8-goodnotes-50.svg',
          route: 'admin-notes',
          desc: 'Admin notes for ssmodules.'
        },
        {
          label: 'Default Dashboards',
          iconType: 'image',
          icon: 'icons8-statistics-50.svg',
          route: 'default-dashboard-management',
          desc: 'Manage default dashboard layouts.'
        },
        {
          label: 'Module Report Dashboards',
          iconType: 'image',
          icon: 'icons8-statistics-50.svg',
          route: 'module-report-dashboard-management',
          desc: 'Manage module report dashboard layouts.'
        },
        {
          label: 'Bumpout Editor',
          iconType: 'image',
          icon: 'icons8-statistics-50.svg',
          route: 'bumpout-editor',
          desc: 'Manage bumpout for this client.'
        },
        {
          label: 'MenuNav Editor',
          iconType: 'image',
          icon: 'icons8-outdent-50.png',
          route: 'menu-nav-editor',
          desc: 'Manage menu-nav for this client.'
        },
        {
          label: 'Yeehro Widgets',
          iconType: 'image',
          icon: 'icons8-statistics-50.svg',
          route: 'yeehro-widget-management',
          desc: 'Manage yeehro widgets.'
        }
      ]
    },
    {
      label: 'Rewards, Games & Rec',
      iconType: 'image',
      icon: 'icons8-cards-50.png',
      sections: [
        {
          label: 'Store Coin',
          iconType: 'image',
          icon: 'icons8-mortgage-50.png',
          route: 'store-coin-editor',
          desc: 'Manage store coin'
        },
        {
          label: 'Store Categories',
          iconType: 'image',
          icon: 'icons8-active-directory-50.png',
          route: 'store-categories-editor',
          desc: 'Manage store categories'
        },
        {
          label: 'Inventory',
          iconType: 'image',
          icon: 'icons8-management-50.png',
          route: 'store-inventory',
          desc: 'Manage store inventory'
        },
        {
          label: 'Orders',
          iconType: 'image',
          icon: 'icons8-order-history-50.png',
          route: 'store-orders',
          desc: 'Manage store transactions'
        },
        {
          label: 'Achievement Management',
          iconType: 'image',
          icon: 'icons8-trophy-50.png',
          route: 'achievements',
          desc: 'Manage achievements'
        },
        {
          label: 'Game Management',
          iconType: 'image',
          icon: 'icons8-game-controller-50.png',
          route: 'games',
          desc: 'Manage games'
        },
        {
          label: 'Current Leaderboard',
          iconType: 'image',
          icon: 'icons8-details-50.png',
          route: 'leaderboard',
          desc: 'View Leaderboard'
        }
      ]
    }
  ];

  systemUpdateEvents = this.socket.fromEvent<any>('system-update-event');

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private _sharedUtilsService: SharedUtilsService,
    private socket: Socket,
    public _systemResetConfirmationDialog: MatDialog
  ) { }


  listenForSystemUpdates() {  
    this.systemUpdateEvents.subscribe(_updateEvent => {
      this.initializeSystemResetDialog();
    });
  }


  async initializeSystemResetDialog() {
    const reminderTime: number = 5; // Minutes until reminder

    const dialogRef = this._systemResetConfirmationDialog.open(SystemResetConfirmationDialogComponent);
    const instance = dialogRef.componentInstance;
    instance.secondsUntilTimeout = 30; // Time in actual secs that dialog will wait until forcing refresh.
    instance.minutesUntilReminder = reminderTime; // To keep reminder time in sync with minutes on dialog button

    const _usersWantsRefresh = await dialogRef.afterClosed().toPromise();
    if (_usersWantsRefresh != undefined && _usersWantsRefresh == true) window.location.reload();
    else setTimeout(() => this.initializeSystemResetDialog(), reminderTime * 60 * 1000);
  }


  get(_id: string): Observable<AdminSetting> {
    return this.httpClient.get<AdminSetting>(this.API_URL + _id);
  }


  getByKey(_key: string): Observable<AdminSetting> {
    return this.httpClient.get<AdminSetting>(this.API_URL + 'by-key/' + _key);
  }


  getAll(): Observable<AdminSetting[]> {
    return this.httpClient.get<AdminSetting[]>(this.API_URL);
  }


  triggerUpdate(): Observable<any> {
    return this.httpClient.post<any>(this.EVENT_URL + 'trigger-update', this.headerOptions);
  }


  emptyCache(): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'empty-cache', this.headerOptions);
  }


  create(_setting): Observable<AdminSetting> {
    return this.httpClient.post<AdminSetting>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  }


  update(_setting: AdminSetting): Observable<AdminSetting> {
    return this.httpClient.put<AdminSetting>(`${this.API_URL}/${_setting._id}`, JSON.stringify(_setting), this.headerOptions);
  }


  delete(id: string): Observable<AdminSetting> {
    return this.httpClient.delete<AdminSetting>(this.API_URL + id);
  }


  search(searchTerms): Observable<AdminSetting[]> {
    return this.httpClient.post<AdminSetting[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  async storeGeneralSettings() {
    this.savedAdminSettings = await this.search({section: 'general-settings'}).toPromise();
  }


  getSavedByKey(_key: string): Promise<AdminSetting> {
    return new Promise(async(resolve) => {
      if (!this.savedAdminSettings) this.savedAdminSettings = await this.search({section: 'general-settings'}).toPromise();    
      const keyToFind = this.savedAdminSettings.find(_s => _s.key === _key);
      resolve(keyToFind);
    });
  }


  roleFormGroup(formData: AdminSetting) {   
    return this.formBuilder.group({
      name: [formData.name, Validators.required],
      key: [formData.key, Validators.required],
      setting: [formData.setting, Validators.required]
    });
  }
}
