import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { CourseSession } from '../models/course-session';
import { Provider } from '../models/provider';

export interface CourseSessionApi {
  items: CourseSession[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class CourseSessionService {
  private currentSessionBeingViewed: CourseSession;

  private API_URL = '/api/course-sessions/';

  headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private courseSessionCreationObs$ = new Subject<CourseSession>();

  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrentSessionBeingViewed(_courseSession: CourseSession): void {
    this.currentSessionBeingViewed = _courseSession;
  }


  getCurrentSessionBeingViewed(): CourseSession {
    return this.currentSessionBeingViewed;
  }


  listenForAttendance() {
    return this.courseSessionCreationObs$;
  }


  announceAttendance(_courseSession: CourseSession) {
    this.courseSessionCreationObs$.next(_courseSession);
  }


  getAll(): Observable<CourseSession[]> {
    return this.httpClient.get<CourseSession[]>(this.API_URL);
  }


  get(id: string): Observable<CourseSession> {
    return this.httpClient.get<CourseSession>(this.API_URL + id);
  }


  getSessionsForCourse(_courseId: string): Observable<CourseSession[]> {
    return this.httpClient.get<CourseSession[]>(this.API_URL + 'by-course/' + _courseId);
  }


  getUnattendedForCourse(_courseId: string): Observable<Provider[]> {
    return this.httpClient.get<Provider[]>(this.API_URL + 'by-course/' + _courseId + '/unattended');
  }


  update(_courseSession: CourseSession): Observable<CourseSession> {
    return this.httpClient.put<CourseSession>(this.API_URL + _courseSession._id, JSON.stringify(_courseSession), this.headerOptions);
  }


  create(_courseSession: CourseSession): Observable<CourseSession> {
    return this.httpClient.post<CourseSession>(this.API_URL, JSON.stringify(_courseSession), this.headerOptions);
  }


  checkAndCreateCourseSessions(_sessionBuildObject): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'checkAndCreateCourseSessions', JSON.stringify(_sessionBuildObject), this.headerOptions); 
  }


  search(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  doSessionSearch(searchTerms: CourseSession): Observable<CourseSession[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<CourseSession[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  getSessionCustomDatasource(searchParams, sortField = 'title', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<CourseSessionApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<CourseSessionApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }


  delete(id: string): Observable<CourseSession> {
    return this.httpClient.delete<CourseSession>(this.API_URL + id);
  }
}