import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { PlacementSchedule } from '@app/models';

export interface PlacementScheduleApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class PlacementScheduleService {
    private currentPlacementSchedule;

    private API_URL = '/api/placement-schedules/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }

    setCurrent(_placementSchedule): void {
        this.currentPlacementSchedule = _placementSchedule;
    }


    getCurrent() {
        return this.currentPlacementSchedule;
    }


    getAll(): Observable<PlacementSchedule[]> {
        return this.httpClient.get<PlacementSchedule[]>(this.API_URL);
    }


    get(_id: string): Observable<PlacementSchedule> {
        return this.httpClient.get<PlacementSchedule>(this.API_URL + _id);
    }


    getByPlacement(_placementId: string): Observable<PlacementSchedule> {
        return this.httpClient.get<PlacementSchedule>(this.API_URL + 'byPlacement/' + _placementId);
    }


    update(_placementSchedule: PlacementSchedule): Observable<PlacementSchedule> {
        return this.httpClient.put<PlacementSchedule>(`${this.API_URL}${_placementSchedule._id}`, JSON.stringify(_placementSchedule), this.headerOptions);
    }


    create(_placementSchedule): Observable<PlacementSchedule> {
        return this.httpClient.post<PlacementSchedule>(this.API_URL, JSON.stringify(_placementSchedule), this.headerOptions);
    }


    search(_searchTerms): Observable<PlacementSchedule[]> {
        return this.httpClient.post<PlacementSchedule[]>(this.API_URL + 'search', JSON.stringify(_searchTerms), this.headerOptions);
    }


    selectAllSearch(searchParams): Observable<PlacementScheduleApi> {
        searchParams['selectAll'] = true;
        return this.httpClient.post<PlacementScheduleApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'createdAt', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<PlacementScheduleApi> {
        searchParams['selectAll'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<PlacementScheduleApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
    }


    delete(_id: string): Observable<PlacementSchedule> {
        return this.httpClient.delete<PlacementSchedule>(this.API_URL + _id);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }
}