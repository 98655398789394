import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

import { AuthService } from './auth.service';
import { GenericModuleService } from './generic-module.service';
import { SharedUtilsService } from './shared-utils.service';
import { SsModuleService } from './ss-module.service';

@Injectable({ providedIn: 'root' })
export class ProviderToolService {
  public relatedProvider: any = null;
  public relatedPlacements: any[] = null;

  constructor(
    private _authService: AuthService,
    private _genericModuleService: GenericModuleService,
    private _sharedUtilsService: SharedUtilsService,
    private _moduleService: SsModuleService
  ) { }


  setupRelatedProviderAndPlacements(): Promise<any> {
    return new Promise(async(resolve) => {
      // Reset these incase they were set previously.
      this.relatedProvider = null;
      this.relatedPlacements = null;

      const userLoggedIn = this._authService.getCurrentlyLoggedInUser();

      if (userLoggedIn?.userType === 'Provider') {
        // const providerModule = await this._moduleService.waitForModule('providers');
        const providerModule = await lastValueFrom(this._moduleService.findByName('providers'));

        if (providerModule?.customSchema) {
          const providerSearchData = {
            name: 'providers',
            schema: providerModule.customSchema,
            searchTerms: {related_user: userLoggedIn._id, deleted: false},
            relationshipsNeeded: providerModule?.relationships
          };

          const providers = await lastValueFrom(this._genericModuleService.search(providerSearchData));
          this.relatedProvider = (providers && providers.length) ? providers[0] : null;
          // console.log('relatedProvider: ', this.relatedProvider);

          if (this.relatedProvider?._id) {
            // If providers_placements gets added to the provider we can just get the placements from it instead.
            if (this.relatedProvider?.providers_placements?.length) {
              this.relatedPlacements = this.relatedProvider.providers_placements;
            } else {
              // const placementModule = await this._moduleService.waitForModule('placements');
              const placementModule = await lastValueFrom(this._moduleService.findByName('placements'));

              if (placementModule?.customSchema) {
                const placementSearchData = {
                  name: 'placements',
                  schema: placementModule.customSchema,
                  searchTerms: {providers_placements: [this.relatedProvider._id], deleted: false},
                  relationshipsNeeded: placementModule?.relationships
                };

                const placements = await lastValueFrom(this._genericModuleService.search(placementSearchData));
                this.relatedPlacements = (placements?.length) ? placements : [];
                // console.log('relatedPlacements: ', this.relatedPlacements);
              }
            }
          }
        }
      }

      resolve(null);
    });
  }
}