import { Injectable } from '@angular/core';

import { Activity, Message, Task } from '@app/models';
import { CalendarEventService } from './calendar-event.service';
import { CredentialingService } from '@app/modules/credentialing/credentialing/credentialing.service';

import { AchievementDetectorService, GameDetectorService, GeoCodingService, MessageService, SsModuleService, TaskService } from '.';

import { ActivityService } from './activity.service';
import { AuditLogService } from './audit-log.service';
import { GenericModuleService } from './generic-module.service';
import { JobService } from './job.service';
import { LeadService } from './lead.service';
import { PtoBalanceService } from './pto-balance.service';
import { SharedUtilsService } from './shared-utils.service';
import { UserService } from './user.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

@Injectable({ providedIn: 'root' })
export class ModuleHooksService {
  constructor(
    private _activityService: ActivityService,
    private _auditService: AuditLogService,
    private _achievementDetectorService: AchievementDetectorService,
    private _calendarEventService: CalendarEventService,
    private _credentialingService: CredentialingService,
    private _gameDetectorService: GameDetectorService,
    private _jobsService: JobService,
    private _geoService: GeoCodingService,
    private _ptoBalanceService: PtoBalanceService,
    private _messageService: MessageService,
    private _moduleService: SsModuleService,
    private _genericModuleService: GenericModuleService,
    private _leadsService: LeadService,
    private _sharedService: SharedUtilsService,
    private _taskService: TaskService,
    private _userService: UserService
  ) { }

  // Happens before the database gets the records, so any changes here are saved to record
  preModuleSaveHook(_module, _uneditedRecord, _updatedRecord): Promise<Object> {
    return new Promise(async(resolve, reject) => {
      // this should probably live elsewhere once the workflow logic is built out

      const _moduleName = _module.name;
      let errorMessage: string = null; // If we want to stop functionality due to issue set an error message with reason and break the case

      // console.log('Module Name: ', _moduleName);
      // console.log('_uneditedRecord: ', _uneditedRecord);
      // console.log('_updatedRecord: ', _updatedRecord);

      switch(_moduleName) { 
        case 'subs': {     
          if (_uneditedRecord) {
            // if editing a record ***** THIS CAN NOW BE MOVED TO A WORKFLOW
            if (_uneditedRecord['status']) {

              if (_uneditedRecord['status'] !== 'Submitted' && _updatedRecord['status'] === 'Submitted') {
                if (_updatedRecord['jobs_subs'] && _updatedRecord['jobs_subs'].job_status) {
                  _updatedRecord['job_status_at_submission'] = _updatedRecord['jobs_subs'].job_status;
                  _updatedRecord['date_submitted'] = new Date();
                  // console.log('Set Job status from job: ', _updatedRecord['jobs_subs'].job_status);
                }
              } 

            //   if (_uneditedRecord['status'] !== 'InterviewScheduled' && _updatedRecord['status'] === 'InterviewScheduled') _updatedRecord['interview_date'] = new Date();
            //   else if (_uneditedRecord['status'] !== 'Submitted' && _updatedRecord['status'] === 'Submitted') _updatedRecord['date_submitted'] = new Date();
              if (_uneditedRecord['status'] !== 'Hired' && _updatedRecord['status'] === 'Hired' && !_updatedRecord['hire_date']) _updatedRecord['hire_date'] = new Date();
            //   else if (_uneditedRecord['status'].indexOf('Rejected') === -1 && _updatedRecord['status'].indexOf('Rejected') !== -1) _updatedRecord['reject_date'] = new Date();
            } else if (_updatedRecord['status'] === 'Submitted' && _updatedRecord['jobs_subs'] && _updatedRecord['jobs_subs'].job_status) {
              _updatedRecord['date_submitted'] = new Date();
              _updatedRecord['job_status_at_submission'] = _updatedRecord['jobs_subs'].job_status;
            }

            // console.log('Job status at submission saved: ', _updatedRecord);

            if (!_uneditedRecord._id) {
              // NEW RECORD
              // const subAccount = _updatedRecord['accounts_subs'];
              // if (subAccount) _updatedRecord.assigned_to = subAccount.assigned_to;

              const subAccount = _updatedRecord['accounts_subs'];

              if (subAccount) {
                let assignedAccountUser = (subAccount && subAccount.assigned_to) ? subAccount.assigned_to : null;

                if (assignedAccountUser && !assignedAccountUser._id) {
                  assignedAccountUser = await lastValueFrom(this._userService.getUser(assignedAccountUser));
                }
  
                // get team named user first name + last name
                const userTeamName = assignedAccountUser['first_name'] + ' ' + assignedAccountUser['last_name']
                const teamModule = await this._moduleService.waitForModule('teams');
  
                const genericSearchData = {
                  name: 'teams',
                  schema: teamModule.customSchema,
                  searchTerms: {name: userTeamName},
                  relationshipsNeeded: null
                };
  
                const userTeams = await lastValueFrom(this._genericModuleService.search(genericSearchData));
                const userTeam = (userTeams && userTeams.length) ? userTeams[0] : null;
                
                // console.log('userTeam: ', userTeam);
                // console.log('Current Teams: ', _updatedRecord['teams']);
                // console.log('userTeam: ', userTeam);
  
                // how do I get the users team from the assigned user on the account
                const existingUserTeam = _updatedRecord['teams'].find(_t => {
                  const tid = (_t._id) ? _t._id : _t;
                  return userTeam && tid == userTeam._id;
                });
  
                if (userTeam && !existingUserTeam) {
                  const globalTeam = await this._userService.getGlobalTeam();
                  const globalTeamId = (globalTeam && globalTeam['_id']) ? globalTeam['_id'] : null;
    
                  _updatedRecord.teams = [globalTeamId, userTeam];
                }
              }

              // console.log('NEW SUB HERE: ', _updatedRecord);

              // Only a handful of states have to have a license - do I need to give you a list of omissions to not require DOE/license for a sub
              const professionsCanExcludeLicenseUpload = ['Administrative Assistant', 'Cafeteria Worker', 'Custodian/Janitor', 'Interpreter/Translator', 'Unlicensed_Healthcare', 'TeacherParaprofessionalAide'];
              // _updatedRecord['license_doe']
  
              const jobSubbingTo = _updatedRecord['jobs_subs'];
              // console.log('Job subbing to: ', jobSubbingTo);
  
              if (jobSubbingTo && jobSubbingTo.profession) {
                const professionFromJob = jobSubbingTo.profession;
                // console.log('Profession from job: ', professionFromJob);
  
                if (!professionsCanExcludeLicenseUpload.includes(professionFromJob)) {
                  // console.log('This sub has been submitted to a job THAT REQUIRES a license. Make sure that file is uploaded.');
                  const currentFile = _updatedRecord['license_doe'];
                  // console.log('Current File: ', currentFile);
  
                  if (!currentFile || !currentFile.length) {
                    errorMessage = 'This profession requries a license. Please choosen one before saving.';
                    break;
                  }
                }
              }

              // set date submitted to current date
              _updatedRecord['date_submitted'] = new Date();
            } else {
              // console.log('UPDATED SUB..');
              // Lynne's request for "moving the sub status to "interview scheduled" when they book an interview for managed" on 6/13/2024

              const accountsToEnforceInterviewScheduled = [
                {_id: '6095ad3cebef337b98b77a50', name: 'MIAMI-DADE COUNTY PUBLIC SCHOOLS'},
                {_id: '6095ad36ebef337b98b7402b', name: 'MIAMI-DADE COUNTY PUBLIC SCHOOLS - CLINIC'},
                {_id: '6095ad3cebef337b98b77345', name: 'BROWARD COUNTY PUBLIC SCHOOLS'}
              ];

              const subsAccount = _updatedRecord['accounts_subs'];
              // console.log('Subs Account: ', subsAccount);

              const subAccountId = (subsAccount._id) ? subsAccount._id : subsAccount;
              const isManagedAccount = (accountsToEnforceInterviewScheduled.find(_sa => _sa._id === subAccountId));

              // console.log('isManagedAccount: ', isManagedAccount);

              if (isManagedAccount && ((_uneditedRecord['interview_booked_date'] === null && _updatedRecord['interview_booked_date'] !== null) || (_uneditedRecord['interview_date'] === null && _updatedRecord['interview_date'] !== null))) {
                if (_updatedRecord['status'] !== 'InterviewScheduled') {
                  errorMessage = 'You must move the status to Interview Schedule when booking an interview for a managed account sub.';
                  break;
                }
              }
            }
          }

          // Related Account and Job are always required!!
          if (!_updatedRecord['accounts_subs'] || !_updatedRecord['jobs_subs']) {
            errorMessage = 'Alls subs require a related account and job. Please set them.';
            break;
          }
          
          break; 
        }
        case 'credentialing': {
          if (!_uneditedRecord || !_uneditedRecord._id) {
            // only applies to new timesheet tracker records
            // console.log('Adding geolocation data to location: ', _updatedRecord);

            // get related account from provider.
            const relatedProviderId = (_updatedRecord.providers_credentialing && _updatedRecord.providers_credentialing._id) ? _updatedRecord.providers_credentialing._id : _updatedRecord.providers_credentialing;
            console.log('Related Provider Id: ', relatedProviderId);

            const provider = await this.getAccountByProvider(relatedProviderId);
            console.log('Related provider found: ', provider);

            if (provider && provider['accounts_providers']) {
              _updatedRecord['related_account'] = provider['accounts_providers'];
            } else if (provider && provider['jobs_providers'] && provider['jobs_providers']['accounts_jobs']) {
              _updatedRecord['related_account'] = provider['jobs_providers']['accounts_jobs'];
            }

            console.log('_updatedRecord: ', _updatedRecord);
          }

          break;
        }
        case 'ptoRequests': {
          if (!_uneditedRecord._id) {
            // console.log('_uneditedRecord: ', _uneditedRecord);
            // console.log('_updatedRecord: ', _updatedRecord);

            if (!_updatedRecord.users_ptoRequests && !_updatedRecord.ptoBalance_ptoRequests) {
              // If no pto request user is present, get it from the pto balance user
              _updatedRecord.users_ptoRequests = _updatedRecord.ptoBalance_ptoRequests.users_ptoBalance;
            }

            let ptoBalanceForYear = await this._ptoBalanceService.getUsersBalanceForRequest(_updatedRecord);
            // console.log('ptoBalanceForYear: ', ptoBalanceForYear);

            if (ptoBalanceForYear) {
              _updatedRecord.ptoBalance_ptoRequests = ptoBalanceForYear;

              const currentBalance = (ptoBalanceForYear.balance) ? ptoBalanceForYear.balance : 0;
              const currentHoursTaken = (ptoBalanceForYear.hours_taken) ? ptoBalanceForYear.hours_taken : 0;
              // console.log('Current Balance for this users related balance record: ', currentBalance);

              const requestedHours: number = (_updatedRecord.hours_requested) ? _updatedRecord.hours_requested : 0;

              if (requestedHours > currentBalance) {
                this._sharedService.showMintNotificationWithOptions("Not have enough PTO time available to fulfill this request. Current balance has " + ptoBalanceForYear.balance + " hours available.", 3000, "danger");
              } else if (requestedHours > 0 && _updatedRecord.status === 'Approved') {
                // This new request was set to approved and the requestedHours are not 0. So update the balance here.
                ptoBalanceForYear.balance = currentBalance - requestedHours;
                ptoBalanceForYear.hours_taken = currentHoursTaken + requestedHours;

                await this._ptoBalanceService.update(ptoBalanceForYear).toPromise();
              }
            } else {
              // this._sharedService.showMintNotificationWithOptions("This user does not have a PTO Balance for the year of PTO date chosen.", 3000, "danger");
              resolve({error: "This user does not have a PTO Balance for the year of this PTO Request."});
            }
          } else {
            // UPDATED PTO REQUEST RECORD

            // console.log('_uneditedRecord: ', _uneditedRecord);
            // console.log('_updatedRecord: ', _updatedRecord);

            let ptoBalanceForYear = await this._ptoBalanceService.getUsersBalanceForRequest(_updatedRecord);
            // console.log('ptoBalanceForYear: ', ptoBalanceForYear);

            const currentBalance = (ptoBalanceForYear.balance) ? ptoBalanceForYear.balance : 0;
            const currentHoursTaken = (ptoBalanceForYear.hours_taken) ? ptoBalanceForYear.hours_taken : 0;
            // console.log('Current Balance for this users related balance record: ', currentBalance);

            let updatePtoBalance = false;

            if (_uneditedRecord.status !== 'Approved' && _updatedRecord.status === 'Approved') {
              // this request has been approved
              if (_updatedRecord.hours_requested <= currentBalance) {
                // update the balance here
                ptoBalanceForYear.balance = currentBalance - _updatedRecord.hours_requested;
                ptoBalanceForYear.hours_taken = currentHoursTaken + _updatedRecord.hours_requested;

                updatePtoBalance = true;
              } else {
                // NOT ENOUGH HOURS in balance
                // This employee does not have enough PTO time available to fulfill this request. They only have $current_pto_balance hours available.
                resolve({error: "This employee does not have enough PTO time available to fulfill this request. They only have " + currentBalance + " hours available."});
              }
            } else if (_uneditedRecord.status === 'Approved' && _updatedRecord.status !== 'Approved') {
              // last request status was approved but has been reversed. Add requested time back to balance and remove hours taken.

              // Do math for adjustments.
              let adjusted_balance = currentBalance + _updatedRecord.hours_requested;
              let adjusted_hours_taken = currentHoursTaken - _updatedRecord.hours_requested;

              // Make sure adjustments are within defined range.
              if (ptoBalanceForYear.allotted_hours && adjusted_balance > ptoBalanceForYear.allotted_hours) adjusted_balance = ptoBalanceForYear.allotted_hours;
              if (adjusted_hours_taken < 0) adjusted_hours_taken = 0;

              // update the balance here
              ptoBalanceForYear.balance = adjusted_balance;
              ptoBalanceForYear.hours_taken = adjusted_hours_taken;

              updatePtoBalance = true; // set flag to perform update.
            } else if (_uneditedRecord.hours_requested !== _updatedRecord.hours_requested) {
              // the hours requested has changed. Check the status and update accordingly
              // console.log("Only the hours were changed. Check the status and update accordingly");

              const hoursRequestedBefore = _uneditedRecord.hours_requested;
              const hoursRequestedAfter = _updatedRecord.hours_requested;

              if (_updatedRecord.status === 'Approved') {
                // don't update the hours unless it has already been approved.

                if(hoursRequestedAfter > hoursRequestedBefore) {
                  // they have requested more hours off than originally requested
                  const additional_hours_requested = hoursRequestedAfter - hoursRequestedBefore;

                  ptoBalanceForYear.balance = currentBalance - additional_hours_requested;
                  ptoBalanceForYear.hours_taken = currentHoursTaken + additional_hours_requested;

                  updatePtoBalance = true;

                  // console.log("More hours are now being requested than originally. Subtracting another " + additional_hours_requested + " from PTO Balance...");
                } else if (hoursRequestedAfter < hoursRequestedBefore) {
                  // they have requested fewer hours off than originally requested
                  const fewer_hours_requested = hoursRequestedBefore - hoursRequestedAfter;
                  // this.addBackPtoBalance($employee_id, $fewer_hours_requested);
                  // console.log("Fewer hours are now being requested than originally. Adding " + fewer_hours_requested + " hours back to PTO Balance...");

                  ptoBalanceForYear.balance = currentBalance + fewer_hours_requested;
                  ptoBalanceForYear.hours_taken = currentHoursTaken - fewer_hours_requested;

                  updatePtoBalance = true;
                }
              }
            }

            if (updatePtoBalance) {
              await this._ptoBalanceService.update(ptoBalanceForYear).toPromise();
            }
          }

          //
          break;
        }
        case 'calls': {
          if (_uneditedRecord) {
            // if editing a record ***** THIS CAN NOW BE MOVED TO A WORKFLOW
            // console.log('Call at submission saved: ', _updatedRecord);

            if (!_uneditedRecord._id) {
              // console.log('NEW Call HERE 1.');

              if (_updatedRecord['leads_activities']) {
                _updatedRecord['leads_calls'] = (_updatedRecord['leads_activities']._id) ? _updatedRecord['leads_activities']._id : _updatedRecord['leads_activities'];
              }
            } else {
              // console.log('CALL UPDATE!!!');
            }
          }

          // console.log('_updatedRecord: ', _updatedRecord);

          break; 
        } 
        case 'accounts': {
          if (!_uneditedRecord._id) {
            // console.log('This is a create for accounts.')
            // console.log(_updatedRecord);

            _updatedRecord['automation_last_ran_date'] = null;
          } else {
            // console.log('Update for accounts. Check if it is a MEMBER OF another account. If it is, change the assigned user to the assigned user on the member of');
            // console.log('Updated Record: ', _updatedRecord);
            // console.log('Assigned user on this account: ', _updatedRecord['member_of'].assigned_to);

            const uneditedRecordAssignedUserId = (_updatedRecord.assigned_to && _updatedRecord.assigned_to._id) ? _updatedRecord.assigned_to._id : _updatedRecord.assigned_to;

            if (_updatedRecord['member_of'] && _updatedRecord['member_of'].assigned_to && uneditedRecordAssignedUserId && uneditedRecordAssignedUserId !== _uneditedRecord['member_of'].assigned_to) {
              // console.log('Assigned to is not the same as member of.');
              _updatedRecord['assigned_to'] = _updatedRecord['member_of'].assigned_to;
              this._sharedService.showErrorDialog("This account is a member of " + _updatedRecord['member_of'].name + '. Changing assigned user to that accounts assigned user.');
            }
          }

          break; 
        }
        case 'tasks': {
          if (!_uneditedRecord || !_uneditedRecord._id) {
            // console.log('_module: ', _module);

            if (!_module.relationships) _module = await this._moduleService.getFullModuleByNameWithRefresh(_module.name);
  
            // console.log('taskModule: ', _module);
            // console.log('This is a create for tasks.')
            // console.log(_updatedRecord);

            const appropriateRel = _module.relationships.find(_rel => _updatedRecord[_rel.name]);
            // console.log('Appropriate Relationship: ', appropriateRel);

            // why is relationship not in task from workflow? Why would it be. Try prod to check for "subs" field there too...
            if (appropriateRel) {              
              _updatedRecord['related_record'] = {
                moduleName: appropriateRel.primary_module.name,
                recordId: (_updatedRecord[appropriateRel.name] && _updatedRecord[appropriateRel.name]._id) ? _updatedRecord[appropriateRel.name]._id : _updatedRecord[appropriateRel.name]
              };
  
              if (_updatedRecord[appropriateRel.name] && !_updatedRecord[appropriateRel.name]._id) {
                // need to get this full record in order to get assigned_to from it.
                const fullRecord = await this.getFullModuleRecord(_updatedRecord[appropriateRel.name], appropriateRel.primary_module.name);
                // console.log('Full Record returned: ', fullRecord);

                _updatedRecord.record_name = (fullRecord['name']) ? fullRecord['name'] : _uneditedRecord.name;

                _updatedRecord['assigned_user_from_record'] = fullRecord['assigned_to'];
              } else {
                _updatedRecord['assigned_user_from_record'] = _updatedRecord[appropriateRel.name]['assigned_to'];
              }
            }

            // console.log('Updated Record now: ', _updatedRecord);
          }

          break; 
        } 
        case 'locations': {
          if (!_uneditedRecord || !_uneditedRecord._id) {
            // only applies to new location records
            // console.log('Adding geolocation data to location: ', _updatedRecord);
            this._sharedService.showMintNotificationWithOptions("Adding geolocation data to this location for you", 1000);
  
            const locationAddress = _updatedRecord.address.street + ', ' + _updatedRecord.address.city + ', ' + _updatedRecord.address.state + ' ' + _updatedRecord.address.zip;
            // console.log('Location Address: ', locationAddress);
            
            if (!_updatedRecord.geoInfo) {
              const geoInfo = await this._geoService.getLatLngFromAddress({source: 'Module Hook Service - Get Location GeoInfo', address: locationAddress}).toPromise();
  
              if (geoInfo) {
                const address = geoInfo[0];
                _updatedRecord.geoInfo = address.geometry.location;
              }
            }
          }

          break; 
        }
        case 'leads': { 
          // console.log('Leads Case...');

          // changed to enforce placement opp rules even if it is already in that status
          if (_updatedRecord['lead_status'] === 'placement_opps') {
          // if (_uneditedRecord['lead_status'] !== 'placement_opps' && _updatedRecord['lead_status'] === 'placement_opps') {
            // console.log('Make sure has placement opps details');

            let missingPlacementOppFields = [];

            // console.log('_updatedRecord: ', _updatedRecord);

            if (!_updatedRecord['available_hours']) missingPlacementOppFields.push('Available Hours');
            if (!_updatedRecord['availability_date']) missingPlacementOppFields.push('Availability Date');
            if (_updatedRecord['new_grad'] && !_updatedRecord['new_grad_expiration']) missingPlacementOppFields.push('New Grad Expiration Date');

            _updatedRecord['placement_opp_automation_ran'] = false;

            // console.log('missingPlacementOppFields: ', missingPlacementOppFields);

            if (missingPlacementOppFields.length) {
              const missingFieldMessage = missingPlacementOppFields.join(', ');
              this._sharedService.showErrorDialog(missingFieldMessage + ' are required for placement opps');

              resolve({error: missingFieldMessage + ' are required for placement opps'});
            }
          }

          if (_uneditedRecord['lead_status'] !== _updatedRecord['lead_status']) {
            // lead status has been updated
            _updatedRecord['previous_status'] = _uneditedRecord['lead_status'];
          }

          this._sharedService.showMintNotificationWithOptions("Finding Matching Jobs", 1000);
          await lastValueFrom(this._leadsService.matchJobsToLead(_updatedRecord));
          
          if (!_uneditedRecord || !_uneditedRecord._id) {
            // console.log('Adding geolocation data to lead: ', _updatedRecord);
            this._sharedService.showMintNotificationWithOptions("Adding geolocation data to this lead for you", 1000);

            if (!_updatedRecord.geoInfo) {
              const leadAddress = _updatedRecord.current_address.street + ', ' + _updatedRecord.current_address.city + ', ' + _updatedRecord.current_address.state + ' ' + _updatedRecord.current_address.zip;
              // console.log('Lead Address: ', leadAddress);
              const geoInfo = await lastValueFrom(this._geoService.getLatLngFromAddress({source: 'Module Hook Service - Get Lead GeoInfo', address: leadAddress}));

              if (geoInfo != undefined && geoInfo.length) {
                const address = geoInfo[0];
                _updatedRecord.geoInfo = address.geometry.location;
              }
            }
          }

          break; 
        }
        case 'timesheetTracker': {
          if (!_uneditedRecord || !_uneditedRecord._id) {
            // only applies to new timesheet tracker records
            // console.log('Adding geolocation data to location: ', _updatedRecord);
            this._sharedService.showMintNotificationWithOptions("Creating timesheet days for start of week through end of week", 1000);

            if (_uneditedRecord.week_start_date && _uneditedRecord.week_end_date) {
              console.log('For each day between this range, create a timesheet day.');
            }
          }

          break;
        }
        case 'jobs': {
          // console.log('Jobs module save hook.');

          if ((_updatedRecord['custom_job_title'] && _updatedRecord['custom_job_title'] !== undefined) &&
          (_updatedRecord['zip'] && _updatedRecord['zip'] !== undefined)) {
            let jobSearchTerms = { custom_job_title: _updatedRecord['custom_job_title'], zip: _updatedRecord['zip'] };

            // If _id, this is a update and we want to allow this job to be excluded from search. Or it will not allow update with the existing title.
            if (_uneditedRecord['_id'] && _uneditedRecord['_id'] !== undefined) jobSearchTerms['idToExcludeFromSearch'] = _uneditedRecord['_id'];

            const jobsMatching = await lastValueFrom(this._jobsService.checkForExistingJobsMatchingData(jobSearchTerms));

            // If matching jobs, we are creating error and breaking case because we don't need remaining code at this time.
            if (jobsMatching && jobsMatching.length) {
              errorMessage = 'Duplicate Job Title. Please Change!';
              break;
            }
          }

          // set the job rating
          _updatedRecord['job_rating'] = await this._jobsService.getRating(_updatedRecord);

          // calculate monthly hours
          if (_updatedRecord.monthly_hours) _updatedRecord['monthly_hours'] = await this._jobsService.processMonthlyHours(_updatedRecord.monthly_hours);
          // console.log('MONTHLY HOURS AFTER FIXING: ', _updatedRecord['monthly_hours']);

          const statusesForCleanup = ['Closed - Won', 'Closed - Lost', 'Cancelled'];
          // if unedited record was not in a cleanup status, but the edited record is, then mark as needing cleanup and add the job closed date.
          if (!statusesForCleanup.includes(_uneditedRecord['job_status']) && statusesForCleanup.includes(_updatedRecord['job_status'])) {
            _updatedRecord['needs_cleanup'] = true;
            _updatedRecord['job_closed_date'] = new Date();

            const currentFeeds = _updatedRecord['included_in_feeds'];
            console.log('Current Feeds: ', currentFeeds);

            _updatedRecord['included_in_feeds'] = currentFeeds.filter(_f => !['indeed'].includes(_f));
            console.log('After removing indeed: ', _updatedRecord['included_in_feeds'])

            // console.log('Marking as needs cleanup: ', _updatedRecord);
          }

          // Set indeed_campaign_boost if the unedited record was false and updated is true.
          if ((_uneditedRecord['indeed_campaign_boost'] == undefined || _uneditedRecord['indeed_campaign_boost'] === false) && _updatedRecord['indeed_campaign_boost'] === true) {
            _updatedRecord['date_last_boosted'] = new Date();
          }

          if (!_uneditedRecord || !_uneditedRecord._id) {
            // new job being created
            // await this._jobsService.matchLeadsToJob(_updatedRecord).toPromise();
            _updatedRecord['post_date'] = new Date();
            _updatedRecord['new_job_processed'] = false;
            
            if (!_updatedRecord['included_in_feeds'] || !_updatedRecord['included_in_feeds'].length) {
              // defaults to all feeds
              _updatedRecord['included_in_feeds'] = ['indeed', 'eduhealthcare.com', 'glassdoor', 'simplyedu', 'linkedin', 'boxwood', 'beyond'];
            }

            // ------      REMOVING AUTOMATED BOOST SETTINGS FOR NOW ----------

            // _updatedRecord['indeed_campaign_boost'] = false;
            // const professionsForBoost = ['BehaviorSpecialist', 'BCABA', 'BoardCertifiedBehaviorAnalyst', 'Occupational Therapist', 'Physical Therapist', 'SocialWorker', 'Speech Language Pathologist'];

            // if (_updatedRecord.profession && professionsForBoost.includes(_updatedRecord.profession)) {
            //   // console.log('Profession for boosting. Check other fields.');
              
            //   const statusesForBoost = ['Accepting Applications', 'Pending Opp'];          

            //   if (statusesForBoost.includes(_updatedRecord.job_status) && _updatedRecord.weekly_hours > 28) {
            //     _updatedRecord['indeed_campaign_boost'] = true;
            //   }
            // }

            // ------------------------------------------------------------------

            if (!_updatedRecord['accounts_jobs'] && _updatedRecord['account']) _updatedRecord['accounts_jobs'] = _updatedRecord['account'];

            // console.log('Adding geolocation data to job: ', _updatedRecord);
            this._sharedService.showMintNotificationWithOptions("Adding geolocation data to this job for you", 1000);

            let jobAddress = null;

            if (_updatedRecord.locations && _updatedRecord.locations.address) {
              jobAddress = _updatedRecord.locations.address.street + ', ' + _updatedRecord.locations.address.city + ', ' + _updatedRecord.locations.address.state + ' ' + _updatedRecord.locations.address.zip;
            } else {
              jobAddress = _updatedRecord.city + ', ' + _updatedRecord.state + ' ' + _updatedRecord.zip;
            }

            // console.log('Job Address: ', jobAddress);
            
            const geoInfo = await this._geoService.getLatLngFromAddress({source: 'Module Hook Service - Get Job GeoInfo', address: jobAddress}).toPromise();

            if (geoInfo) {
              const address = geoInfo[0];
              _updatedRecord.geoInfo = (address && address.geometry) ? address.geometry.location : null;
            }
          }

          break;
        }
        default: { 
          break;
        }
      }

      // If error just resolve the error, else complete hook
      if (errorMessage && errorMessage !== undefined) {
        console.log('There was an error with preHook: ', errorMessage)
        resolve({ error: errorMessage});
      } else {
        if (_uneditedRecord && _uneditedRecord._id) {
          // only do audit log checks if there was a previous record, i.e. doing an update
          this._auditService.createChangeEntry(_uneditedRecord, _updatedRecord, _module);
        }

        if (!_updatedRecord.teams || !_updatedRecord.teams.length) {
          const globalTeam = await this._userService.getGlobalTeam();
          // console.log('Adding global team to this record by default: ', globalTeam);

          const globalTeamId = (globalTeam && globalTeam['_id']) ? globalTeam['_id'] : null;
          _updatedRecord.teams = [globalTeamId];
        }

        // console.log('Record after pre hook complete: ', _updatedRecord);
        resolve(_updatedRecord);
      }
    });
  }


  // happens after database saves or updates
  postSaveHook(_module, _moduleRecord, relateDetails, _uneditedRecord = null, _multiRelateDetails = null) {
    return new Promise(async(resolve, reject) => {
      // this should probably live elsewhere once the workflow logic is built out

      const _moduleName = _module.name;
      // console.log('Module Name in postSaveHook: ', _moduleName);

      console.log('relateDetails: ', relateDetails);
      console.log('_uneditedRecord: ', _uneditedRecord);
      console.log('_moduleRecord: ', _moduleRecord);
      console.log('_multiRelateDetails: ', _multiRelateDetails);

      switch(_moduleName) { 
        case 'calls': {
          const newActivity = new Activity();
          newActivity.activityType = 'Call';
          newActivity.created_by = _moduleRecord.created_by;
          newActivity.assigned_to = _moduleRecord.assigned_to;
          newActivity.modified_by = _moduleRecord.modified_by;
          newActivity.relatedModule = {moduleType: 'calls', moduleRecordId: _moduleRecord._id};
          newActivity.relatedRecord = relateDetails;
          newActivity.relatedRecords = [relateDetails];
          newActivity.deleted = false;

          // if contact was passed up and account, and a contact was chosen, add both
          if (_multiRelateDetails) {
            newActivity.relatedRecords = _multiRelateDetails;

            // relate details is other side
            const otherRecord = _multiRelateDetails.find(_rd => _rd.recordType !== relateDetails.recordType);
            // console.log('Other side is primary record for this: ', otherRecord);
            newActivity.relatedRecord = otherRecord;
            newActivity.relatedRecords = _multiRelateDetails;
          }

          // console.log('Creating activity associated to a call: ', newActivity);

          const savedActivity = await lastValueFrom(this._activityService.create(newActivity));
          this._activityService.announceNewActivity(savedActivity);

          break; 
        }
        case 'notes': {
          const newActivity = new Activity();
          newActivity.activityType = 'Note';
          newActivity.created_by = _moduleRecord.created_by;
          newActivity.assigned_to = _moduleRecord.assigned_to;
          newActivity.modified_by = _moduleRecord.modified_by;
          newActivity.relatedModule = {moduleType: 'notes', moduleRecordId: _moduleRecord._id};
          newActivity.relatedRecord = relateDetails;
          newActivity.relatedRecords = [relateDetails];
          newActivity.deleted = false;

          // if contact was passed up and account, and a contact was chosen, add both
          if (_multiRelateDetails) newActivity.relatedRecords = _multiRelateDetails;
          else newActivity.relatedRecords = [relateDetails];

          const savedActivity = await lastValueFrom(this._activityService.create(newActivity));
          this._activityService.announceNewActivity(savedActivity);

          break; 
        }
        case 'jobs': {
          // console.log('Jobs module save hook.');

          if (!_uneditedRecord || !_uneditedRecord._id) {

            // any new job should always get matching job
            // console.log('Matching leads for this job: ', _moduleRecord);

            this._sharedService.showMintNotificationWithOptions("Job has been scheduled for matching/automation", 1000);

            // this._sharedService.showMintNotificationWithOptions("Finding Matching Leads", 1000);
            // await this._jobsService.matchLeadsToJob(_moduleRecord).toPromise();

            // if (!_moduleRecord.dont_trigger_automation) {
            //   // console.log('****** TRIGGER JOB WORKFLOW AUTOMATION *****');
            //   this._sharedService.showMintNotificationWithOptions("Triggering Workflow Automation on Matching Leads", 1000);
            //   await this._leadsService.performWorkflowAutomationMatchingLeads({jobId: _moduleRecord._id}).toPromise();
            // } else {
            //   // console.log('Do not match is set on this job');
            //   this._sharedService.showNotification("Do not match is set. Bypassing Matching and Lead Automation.");
            // }


            // create campaigns if set

            // console.log('New job created: ', _moduleRecord);

            // if (_moduleRecord.send_auto_campaigns) {
            //   console.log('Auto Campaigns NOT set on this job. Create them');

            //   this._sharedService.showMintNotificationWithOptions("Auto Creating Campaigns", 1000);
            //   await this._jobsService.autoGenerateCampaignsFromJob(_moduleRecord).toPromise();
            //   this._sharedService.showNotification("SMS Campaign has been created for this job.");
            // }
          }

          break;
        }
        case 'rfpPipeline': {
          if (!_uneditedRecord || !_uneditedRecord._id) {
            // console.log('New RFP.');
            if (_moduleRecord.due_date) {
              await this._calendarEventService.addRfpDueDateToCalendar(_moduleRecord);
            }
          } else {
            console.log('RFP getting updated, not created.');
          }

          break;
        }
        case 'placements': {
          if (!_uneditedRecord || !_uneditedRecord._id) {
            // console.log('New placement getting created.');

            // get the sub associated with placement. It should have the provider on it, which has the relatedUserId
            // console.log('_moduleRecord: ', _moduleRecord);
            
            const relatedProvider = _moduleRecord['providers_placements'];
            let relatedAccount = _moduleRecord['accounts_placements'];
            
            if (!relatedAccount && relatedProvider && relatedProvider['subs_providers'] && relatedProvider['subs_providers']['accounts_subs']) relatedAccount = relatedProvider['subs_providers']['accounts_subs'];

            // console.log('relatedProvider: ', relatedProvider);
            // console.log('relatedAccount: ', relatedAccount);

            // await this.addPlacementStartDatesToCalendar(_moduleRecord, relatedAccount);
            if (_moduleRecord.expected_start_date) {
              await this._calendarEventService.addPlacementStartDatesToCalendar(_moduleRecord, relatedAccount);
            }

            const relatedAccountId = (relatedAccount && relatedAccount['_id']) ? relatedAccount['_id'] : relatedAccount;

            if (relatedAccount) _moduleRecord['accounts_placements'] = relatedAccountId;
            // console.log('_moduleRecord: ', _moduleRecord);

            const providerRelatedUser = (relatedProvider && relatedProvider['related_user']) ? relatedProvider['related_user'] : null;
            const currentLoggedInUser = this._userService.getCurrentlyLoggedInUser();
            
            // lookup the account this placement belongs to. If it has a credentialing setup record matching account and profession, then create a credentialing record from it
            const savedCredentialingRecord = await this._credentialingService.checkAndCreateCredentialing(_moduleRecord, providerRelatedUser, currentLoggedInUser);

            if (savedCredentialingRecord && savedCredentialingRecord['_id']) {
              _moduleRecord['placements_credentialing'] = savedCredentialingRecord['_id'];
            }

            const placementModule = await this._moduleService.waitForModule('placements');

            const customModuleUpdate = {
              record: _moduleRecord,
              name: 'placements',
              schema: placementModule.customSchema
            };
            
            await lastValueFrom(this._genericModuleService.update(customModuleUpdate));


            // workflow cannot currently create a related record to a task
            if (_moduleRecord && _moduleRecord._id) {
              const taskStartDate = new Date();
              taskStartDate.setDate(taskStartDate.getDate() + 7); // Set now + 30 days as the new date

              const taskDueDate = new Date();
              taskDueDate.setDate(taskDueDate.getDate() + 14); // Set now + 30 days as the new date

              const _taskDefaults = {
                assignTo: '610a930ae8e2185996357e91',
                taskTitle: 'Send New Placements Work Schedule',
                taskType: 'Marketing',
                parentType: 'placements',
                notificationMessage: `A task has been assigned to you for Sending New Placements Work Schedule: `,
                description: `Send a work schedule to ${_moduleRecord.name}`,
                startDate: taskStartDate,
                dueDate: taskDueDate,
                relationshipKey: 'placements_tasks',
                relatedRecord: { moduleName: 'placements', recordId: _moduleRecord._id}
              };

              await this.flexTaskCreate(_taskDefaults);
            }
          } else {
            // console.log('Placement getting updated, not created.');
          }


          if (!_uneditedRecord || !_uneditedRecord._id || (!['PendingAccountSignature', 'Active'].includes(_uneditedRecord.placement_status)) && (['PendingAccountSignature', 'Active'].includes(_moduleRecord.placement_status))) {
              // When a Placement is moved to Pending Account Signature or Active status
              // - Create Task - Confirm Pay Rate in ADP
              // - Create Task - Confirm Bill Rate in GHG
  
              // console.log('TRIGGERING PAY RATE EMAIL and stuff.');

              const taskStartDate = new Date();
              taskStartDate.setDate(taskStartDate.getDate() + 1); // Set now + 30 days as the new date
        
              const _taskDefaults = {
                assignTo: "6614346af5957bbb63da7b20",
                taskTitle: 'Confirm Pay Rate in ADP',
                taskType: 'Administration',
                parentType: 'placements',
                notificationMessage: `A task has been created and assigned to you for Confirm Pay Rate in ADP: `,
                description: `Confirm Pay Rate in ADP for placement: ${_moduleRecord.name}`,
                startDate: taskStartDate,
                dueDate: null,
                relationshipKey: 'placements_tasks',
                relatedRecord: { moduleName: 'placements', recordId: _moduleRecord._id}
              };
              await this.flexTaskCreate(_taskDefaults, true);
  
              // send another for term provider...
              _taskDefaults.taskTitle = 'Confirm Bill Rate in GHG';
              _taskDefaults.description = `Confirm Bill Rate in GHG for placement: ${_moduleRecord.name}`,
              _taskDefaults.notificationMessage = 'A task has been created and assigned to you for Confirm Bill Rate in GHG: ';
              await this.flexTaskCreate(_taskDefaults, true);
          }


          break;

          // if (!_uneditedRecord || !_uneditedRecord._id) {
          //   console.log('New placement getting created.');

          //   // lookup the account this placement belongs to. If it has a credentialing setup record matching account and profession, then create a credentialing record from it
          //   const savedCredentialingRecord = await this._credentialingService.checkAndCreateCredentialing(_updatedRecord);
          //   console.log('savedCredentialingRecord: ', savedCredentialingRecord);

          //   // if (savedCredentialingRecord && savedCredentialingRecord['_id']) {
          //   //   _updatedRecord['placements_credentialing'] = savedCredentialingRecord;
          //   //   console.log('Added placements credentialing relate to updated record: ', _updatedRecord);
          //   // }
          // } else {
          //   console.log('Placement getting updated, not created.');
          // }

          // break;
        }
        case 'providers': {
          // console.log('Creating a provider. Make a user account with provider type.');
          // console.log('_uneditedRecord: ', _uneditedRecord);
          // console.log('_moduleRecord: ', _moduleRecord);

          if (!_uneditedRecord || !_uneditedRecord._id) {
            // new record only
            // console.log('New Record: ', _moduleRecord);

            if (!_moduleRecord.related_user && _moduleRecord.email && _moduleRecord.email.length) {
              const savedUser = await this._userService.createUserForProvider(_moduleRecord);
              console.log('Saved user: ', savedUser);

              if (!savedUser) {
                this._sharedService.showErrorDialog("Error creating user for provider.");
              }

              _moduleRecord.related_user = savedUser._id;
              _moduleRecord.locker_account_enabled = true;
  
              const providerModule = await this._moduleService.waitForModule('providers');
  
              const customModuleUpdate = {
                record: _moduleRecord,
                name: 'providers',
                schema: providerModule.customSchema
              };
              
              _moduleRecord = await this._genericModuleService.update(customModuleUpdate).toPromise();
            } else if (_uneditedRecord && _uneditedRecord.status === 'Active' && _moduleRecord.status !== 'Active' && _moduleRecord.related_user) {
              // deactivate provider user
              // console.log('Deactivating provider user');
              let relatedUser = _moduleRecord.related_user;
  
              if (relatedUser && !relatedUser._id) {
                relatedUser = await lastValueFrom(this._userService.getUser(relatedUser));
              }
  
              relatedUser.active = false;
              await lastValueFrom(this._userService.updateUser(relatedUser));
            } else if (_uneditedRecord && _uneditedRecord.status !== 'Active' && _moduleRecord.status === 'Active' && _moduleRecord.related_user) {
              // reactivate provider user
              // console.log('Reactivating provider user');
              let relatedUser = _moduleRecord.related_user;
  
              if (relatedUser && !relatedUser._id) {
                relatedUser = await lastValueFrom(this._userService.getUser(relatedUser));
              }
  
              relatedUser.active = true;
              await lastValueFrom(this._userService.updateUser(relatedUser));
            } else {
              console.log('No case to apply...');
            }
          } else {
            // existing provider
            // console.log('Existing provider in save hook...');
            
            let newUserActiveStatus = null;

            // console.log('_uneditedRecord: ', _uneditedRecord);
            // console.log('_uneditedRecord.locker_account_enabled: ', _uneditedRecord.locker_account_enabled);
            // console.log('_moduleRecord.related_user: ', _moduleRecord.related_user);
            // console.log('_moduleRecord.locker_account_enabled: ', _moduleRecord.locker_account_enabled);

            if (_uneditedRecord && !_uneditedRecord.locker_account_enabled && _moduleRecord.related_user && _moduleRecord.locker_account_enabled) {
              // locker account enabled has been toggled to active
              // console.log('locker account enabled has been toggled to active');
              newUserActiveStatus = true;
            } else if (_uneditedRecord && _uneditedRecord.locker_account_enabled && _moduleRecord.related_user && !_moduleRecord.locker_account_enabled) {
              // locker account enabled has been toggled to inactive
              // console.log('locker account enabled has been toggled to inactive');
              newUserActiveStatus = false
            }

            // console.log('newUserActiveStatus: ', newUserActiveStatus);

            if (newUserActiveStatus != null) {
              let relatedUser = _moduleRecord.related_user;
  
              if (relatedUser && !relatedUser._id) {
                relatedUser = await lastValueFrom(this._userService.getUser(relatedUser));
              }
  
              relatedUser.active = newUserActiveStatus;
              await lastValueFrom(this._userService.updateUser(relatedUser));
            }

            if (_uneditedRecord && _uneditedRecord.status === 'Active' && ['PendingInactive', 'Inactive'].includes(_moduleRecord.status)) {
              // when a provider is moved from active to either inactive or pending inactive status, then update related leads status to future opp if it is not in that status.
              // console.log('_moduleRecord (look at lead for status): ', _moduleRecord);

              const relatedLeadStatus = (_moduleRecord['leads_providers'] && _moduleRecord['leads_providers'].lead_status) ? _moduleRecord['leads_providers'].lead_status : null;

              const relatedLeadId = (_moduleRecord['leads_providers'] && _moduleRecord['leads_providers']._id) ? _moduleRecord['leads_providers']._id : _moduleRecord['leads_providers'];

              if (!relatedLeadStatus || (relatedLeadStatus !== 'future_opp')) {
                // set lead as future opp.
                await lastValueFrom(this._leadsService.updateLeadStatus({leadId: relatedLeadId, oldStatus: relatedLeadStatus, newStatus: 'future_opp', changeSource: 'Provider Changed to Inactive'}));
                this._sharedService.showNotification("Update the status of this related lead to future opp.");
              } else {
                console.log('Lead status found that was future opp: ', relatedLeadStatus);
              }
            }

            if (_uneditedRecord && _uneditedRecord.status === 'Active' && _moduleRecord.status === 'PendingInactive') {
              // When a Provider is moved from Active to Pending Inactive status, 2 tasks are needed:
              // - Deactivate Provider in GHG
              // - Term Provider in ADP Payroll
              let recordName = null;
              let alreadyLastName = false;

              if (_moduleRecord.last_name && _moduleRecord.last_name.length) {
                recordName = _moduleRecord.last_name;
                alreadyLastName = true;
              } else {
                recordName = (_moduleRecord.name && _moduleRecord.name.length) ? _moduleRecord.name.toLowerCase() : _moduleRecord.name;
              }

              const userToAssign = await this.getAlphaUserForProviderTask(recordName, alreadyLastName);
              // console.log('userToAssign: ', userToAssign);

              const taskStartDate = new Date();
              taskStartDate.setDate(taskStartDate.getDate() + 3); // Set now + 30 days as the new date
        
              const taskDueDate = new Date();
              taskDueDate.setDate(taskDueDate.getDate() + 10); // Set now + 30 days as the new date
        
              const _taskDefaults = {
                assignTo: userToAssign,
                taskTitle: 'Deactivate Provider in GHG',
                description: `Deactivate provider in GHG: ${_moduleRecord.name}`,
                taskType: 'Payroll',
                parentType: 'providers',
                notificationMessage: `A task has been created and assigned to you for Deactivate Provider in GHG: `,
                startDate: taskStartDate,
                dueDate: taskDueDate,
                relationshipKey: 'providers_tasks',
                relatedRecord: { moduleName: 'providers', recordId: _moduleRecord._id}
              };
              await this.flexTaskCreate(_taskDefaults);

              // send another for term provider...
              _taskDefaults.taskTitle = 'Term Provider in ADP Payroll';
              _taskDefaults.description = `Term Provider in ADP Payroll: ${_moduleRecord.name}`,
              _taskDefaults.notificationMessage = 'A task has been created and assigned to you for Term Provider in ADP Payroll: ';
              await this.flexTaskCreate(_taskDefaults);
            }
          }

          if (!_uneditedRecord || !_uneditedRecord._id || (_uneditedRecord && _uneditedRecord.status !== 'Active' && _moduleRecord.status === 'Active')) {
            // When a Provider is moved to active:
            // - Deactivate Provider in GHG
            // - Term Provider in ADP Payroll

            console.log('Module Record: ', _moduleRecord);

            let recordName = null;
            let alreadyLastName = false;

            if (_moduleRecord.last_name && _moduleRecord.last_name.length) {
              recordName = _moduleRecord.last_name;
              alreadyLastName = true;
            } else {
              recordName = (_moduleRecord.name && _moduleRecord.name.length) ? _moduleRecord.name.toLowerCase() : _moduleRecord.name;
            }

            const userToAssign = await this.getAlphaUserForProviderTask(recordName, alreadyLastName);
            // console.log('userToAssign: ', userToAssign);

            const taskStartDate = new Date();
            taskStartDate.setDate(taskStartDate.getDate() + 1);
      
            const taskDueDate = new Date();
            taskDueDate.setDate(taskDueDate.getDate() + 8);
      
            const _taskDefaults = {
              assignTo: userToAssign,
              taskTitle: 'Enter Hire Date/Placement Start Date',
              taskType: 'Payroll',
              parentType: 'providers',
              notificationMessage: `A task has been created and assigned to you for Enter Hire Date/Placement Start Date: `,
              startDate: taskStartDate,
              dueDate: taskDueDate,
              relationshipKey: 'providers_tasks',
              relatedRecord: { moduleName: 'providers', recordId: _moduleRecord._id}
            };
            await this.flexTaskCreate(_taskDefaults);
          }

          break;
        }
        case 'subs': {
          // console.log('Create a sub activity from this record: ', _moduleRecord);

          if (!_uneditedRecord || !_uneditedRecord._id) {
            // new sub
            const newActivity = new Activity();
            newActivity.activityType = 'Submission';
            newActivity.created_by = _moduleRecord.created_by;
            newActivity.assigned_to = _moduleRecord.assigned_to;
            newActivity.modified_by = _moduleRecord.modified_by;
            newActivity.relatedModule = {moduleType: 'subs', moduleRecordId: _moduleRecord._id};
            newActivity.relatedRecord = relateDetails;
            newActivity.relatedRecords = [relateDetails];
            newActivity.deleted = false;
  
            await lastValueFrom(this._activityService.create(newActivity));
            // await this._taskService.createTaskForSub(_moduleRecord);
          } else {
            // console.log('Sub update here.');

            if (_uneditedRecord['status'] !== 'Hired' && _moduleRecord['status'] === 'Hired') {
              _moduleRecord['hire_date'] = new Date();
              // create task Request Indeed/Glassdoor/Google Review
              const relatedLead = _moduleRecord['leads_subs'];

              if (relatedLead && relatedLead.assigned_to) {
                const taskStartDate = new Date();
                taskStartDate.setDate(taskStartDate.getDate() + 30); // Set now + 30 days as the new date

                const taskDueDate = new Date();
                taskDueDate.setDate(taskDueDate.getDate() + 37); // Set now + 30 days as the new date

                const _taskDefaults = {
                  assignTo: relatedLead.assigned_to,
                  taskTitle: 'Gather Feedback Indeed/Glassdoor',
                  taskType: 'Marketing',
                  parentType: 'subs',
                  notificationMessage: `A task has been created and assigned to you for Gathering Feedback Indeed/Glassdoor: `,
                  startDate: taskStartDate,
                  description: `Gathering Feedback Indeed/Glassdoor for sub: ${_moduleRecord.name}`,
                  dueDate: taskDueDate,
                  relationshipKey: 'subs_tasks',
                  relatedRecord: { moduleName: 'subs', recordId: _moduleRecord._id}
                };

                await this.flexTaskCreate(_taskDefaults);
              }
            } else if (_uneditedRecord['status'] !== 'InterviewScheduled' && _moduleRecord['status'] === 'InterviewScheduled') {
              // moved to interview scheduled..

              // Create a task for recruiter to call to confirm their interviews the day of the scheduled event
              // - The task would be assigned to the assigned user of the sub, and it would show the "related sub" in the task. 
              // - The start and due date would be the date of the interview. 

              // console.log('_moduleRecord; ', _moduleRecord);

              const _taskDefaults = {
                assignTo: _moduleRecord.assigned_to,
                taskTitle: 'Call and Confirm Interview for Sub',
                taskType: 'Marketing',
                parentType: 'subs',
                notificationMessage: `Please call and confirm the interview scheduled for today regarding <a href="https://www.yeehro.com/app/subs/${_moduleRecord._id}">${_moduleRecord.name}</a>.`,
                startDate: _moduleRecord.interview_date,
                dueDate: _moduleRecord.interview_date,
                relationshipKey: 'subs_tasks',
                relatedRecord: { moduleName: 'subs', recordId: _moduleRecord._id}
              };

              await this.flexTaskCreate(_taskDefaults);
            }
          }

          break;
        }
        case 'tasks': {
          // console.log('Task Saved. Announcing: ', _moduleRecord);
          this._taskService.announceEventServerYeehroTasks(_moduleRecord);
          this._taskService.announceClearTasks();
          break;
        }
        case 'ptoRequests': {
          const _recordIdAsString = _moduleRecord._id.toString();
          const existCalendarEvent = await this._calendarEventService.searchForOne({related_pto_request_id: _recordIdAsString}).toPromise();

          // If not an existing event for record and it  add one.
          if (existCalendarEvent == undefined && _moduleRecord['status'] !== 'Cancelled') {
            // console.log('Also add this to the CALENDAR!');
            await this._calendarEventService.addPtoRequestToCalendar(_moduleRecord);
          } else if (existCalendarEvent != undefined && _moduleRecord['status'] === 'Cancelled') {
            // If there is a calendar event and the record is cancelled we don't need the event anymore.
            await this._calendarEventService.deleteEvent(existCalendarEvent._id).toPromise();
          } else if (existCalendarEvent != undefined) {
            await this._calendarEventService.updateEventForPtoRequest(existCalendarEvent, _moduleRecord);
          }
          break;
        }
        default: { 
          break; 
        }
      }

      // we can trigger achievement checks here...
      await this._achievementDetectorService.triggerAchievementDetector(_module, _uneditedRecord, _moduleRecord);
      // console.log('Achievement detector has finished running...');

      // we can trigger game checks here...
      await this._gameDetectorService.triggerGameDetector(_module, _uneditedRecord, _moduleRecord);
      // console.log('Game detector has finished running...');

      resolve(null);
    });
  }


  flexTaskCreate(_defaults, _disableEmail = false) {
    return new Promise(async(resolve) => {
      // const _taskDefaults = {
      //   assignTo: xxx,
      //   taskTitle: 'Deactivate Provider in GHG',
      //   taskType: 'Marketing',
      //   parentType: 'providers',
      //   notificationMessage: `A task has been created and assigned to you for Deactivate Provider in GHG: `
      //   startDate: xxx,
      //   dueDate: xxx,
      //   relationshipKey: 'providers_tasks',
      //   relatedRecord: { moduleName: 'providers', recordId: _moduleRecord._id}
      // };

      // console.log('_defaults: ', _defaults);

      let assignedToFullUser = (_defaults.assignTo && _defaults.assignTo._id) ? _defaults.assignTo : null;
      // console.log('Assign to full user: ', assignedToFullUser);

      if (!assignedToFullUser) {
        assignedToFullUser = await lastValueFrom(this._userService.getUser(_defaults.assignTo));
      }

      if (assignedToFullUser && assignedToFullUser.email) {
        const newTask = new Task();
        newTask.assigned_to = assignedToFullUser._id;
        newTask.name = _defaults.taskTitle;
        newTask.subject = _defaults.taskTitle;
        newTask.status = 'Not Started';
        newTask.task_type = _defaults.taskType;
        newTask.start_date = _defaults.startDate;
        newTask.due_date = _defaults.dueDate;
        newTask.description = (_defaults.description) ? _defaults.description : _defaults.taskTitle;
        newTask.parent_type = _defaults.relatedRecord.moduleName;
        newTask.deleted = false;
        newTask[_defaults.relationshipKey] = _defaults.relatedRecord.recordId;
        newTask.related_record = _defaults.relatedRecord;
  
        const savedTask = await lastValueFrom(this._taskService.create(newTask));
        
        // now send a notification email to person assigned to the task
        const newMessage = new Message();
        newMessage.name = 'New Task Assigned';
        newMessage.subject = 'New Task Assigned';
        newMessage.to = assignedToFullUser.email;
        newMessage.sentFrom = 'yeehro@eduhealthcare.com';
        newMessage.direction = 'outgoing';
        newMessage.status = 'Sent';
        newMessage.message = _defaults.notificationMessage + ` <a href="https://www.yeehro.com/app/tasks/${savedTask._id}">View Task</a>`;
        newMessage.messageType = 'email';
        newMessage.deleted = false;
  
        // console.log('Would send alert email: ', newMessage);

        if (_disableEmail) {
          await lastValueFrom(this._messageService.sendAlertEmail({messageRecord: newMessage}));
        }
      }

      resolve(null);
    });
  }


  getAlphaUserForProviderTask(_recordName, alreadyLastName = false) {
    return new Promise(async(resolve) => {
      // If last name of Provider is A-F the tasks should go to Desmond Flynn
      // If last name of Provider is G-M the tasks should go to Angela Grash
      // If last name of Provider is N-Z the tasks should go to Rosaura Rodriguez

      let userIdToAssignTo = null;
      let relatedUser = null;

      // console.log('_recordName: ', _recordName);
      
      const nameParts = _recordName.split(' ');
      let lastName = _recordName;
      
      if (!alreadyLastName) {
        // console.log('Must get last name from spaces in name field.');
        if (nameParts.length && nameParts.length > 2) {
          // spaced first or last name, either way, best we can do is get the LAST 2 spaced parts.
          lastName = nameParts[nameParts.length - 2] + ' ' + nameParts[nameParts.length - 1];
          // console.log('Spaced last name with 3 parts. Getting from last two parts: ', lastName);
        } else {
          // name only has 2 parts (only 1 space)
          lastName = (nameParts && nameParts.length) ? nameParts[nameParts.length - 1] : null;
        }
      }

      // console.log('LAST NAME FOR assigning user: ', lastName);

      if (lastName) {
        // console.log('lastName: ', lastName);
        const lastNameFirstCharCode = lastName.toLowerCase().charCodeAt(0);
        // console.log('charCodeAt: ', lastNameFirstCharCode);

        if (lastNameFirstCharCode > 96 && lastNameFirstCharCode < 103) {
          // a is 97 and f is 102
          // If last name of Provider is A-F the tasks should go to Desmond Flynn
          // console.log('Name is between A - F...');
          userIdToAssignTo = '610a930ae8e2185996357eae';
        } else if (lastNameFirstCharCode > 102 && lastNameFirstCharCode < 110) {
          // g is 103 and m is 109
          // If last name of Provider is G-M the tasks should go to Angela Grash
          // console.log('Name is between G - M...');
          userIdToAssignTo = '61f3ea64c326350494a850b7';
        } else {
          // If last name of Provider is N-Z the tasks should go to Rosaura Rodriguez
          // console.log('Name is between N - Z...');
          userIdToAssignTo = '610a930ae8e2185996357e3d';
        }

        // console.log('userIdToAssignTo: ', userIdToAssignTo);
  
        if (userIdToAssignTo) {
          // console.log('Getting user by id: ', userIdToAssignTo);
          relatedUser = await lastValueFrom(this._userService.getUser(userIdToAssignTo));
        }
      }

      // console.log('Related User for this task: ', relatedUser);

      resolve(relatedUser);
    });
  }


  getFullModuleRecord(_recordId, _moduleName) {
    return new Promise(async(resolve) => {
      const neededModule = await this._moduleService.waitForModule(_moduleName);

      const moduleOptions = {
        name: _moduleName,
        schema: neededModule.customSchema,
        searchTerms: {
          _id: _recordId,
        }
      };

      const _record = await this._genericModuleService.get(moduleOptions).toPromise();
      resolve(_record);
    });
  }


  async getAccountByProvider(_providerId) {
    console.log('_providerId: ', _providerId);
    return new Promise(async(resolve) => {
      const providerModule = await this._moduleService.waitForModule('providers');
      // const providerFields = await this._moduleService.getModuleFieldsWithRelationships(providerModule);

      const provId = (_providerId && _providerId._id) ? _providerId._id : _providerId;

      const genericSearchData = {
        name: providerModule.name,
        schema: providerModule.customSchema,
        searchTerms: {_id: provId},
        relationshipsNeeded: providerModule.relationships
      };

      console.log('genericSearchData: ', genericSearchData);

      const _provider = await lastValueFrom(this._genericModuleService.getWithRelationships(genericSearchData));
      resolve(_provider);
    });
  }


  async getProviderBySub(_subId) {
    return new Promise(async(resolve) => {
      const providerModule = await this._moduleService.waitForModule('providers');
      const providerFields = await this._moduleService.getModuleFieldsWithRelationships(providerModule);

      const genericSearchData = {
        name: 'providers',
        schema: providerModule.customSchema,
        searchTerms: {
          sortField: 'createdAt',
          sortOrder: 'desc',
          fields: providerFields,
          inclusionSearch: {
            nonCache: true,
            selectAll: true,
            subs_providers: {inclusionType: 'Include', value: [_subId]}
          },
        },
        relationshipsNeeded: null
      };
  
      const _providers = await this._genericModuleService.selectAllSearch(genericSearchData).toPromise();
      const _provider = (_providers && _providers.length) ? _providers[0] : null;

      resolve(_provider);
    });
  }
}
