import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { SmsSettings } from '../models/smsSettings';

@Injectable({ providedIn: 'root' })
export class SmsSettingsService {
  private API_URL = '/api/sms-settings/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  private currentSettings: SmsSettings;
  private settingsObs$ = new Subject<any>();
  
  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrent(_settings: SmsSettings): void {
    this.currentSettings = _settings;
  }


  getCurrent(): SmsSettings {
    return this.currentSettings;
  }


  listenForSettingsChange() {
    return this.settingsObs$;
  }


  announceSettingsChange(_settings) {
    this.currentSettings = _settings;
    this.settingsObs$.next(_settings);
  }


  getSettingsForCurrentUser(_userId): Promise<SmsSettings> {
    return new Promise(async(resolve) => {
      let currentSettings = this.currentSettings;

      if (!currentSettings) {
        currentSettings = await lastValueFrom(this.getByUser(_userId));
        this.currentSettings = currentSettings;
      }

      resolve(currentSettings);
    });
  }


  get(_id: string): Observable<SmsSettings> {
    return this.httpClient.get<SmsSettings>(this.API_URL + _id);
  }


  getByUser(_userId: string): Observable<SmsSettings> {
    return this.httpClient.get<SmsSettings>(this.API_URL + 'by-user/' + _userId);
  }


  getAll(): Observable<SmsSettings[]> {
    return this.httpClient.get<SmsSettings[]>(this.API_URL);
  }


  create(_setting): Observable<SmsSettings> {
    return this.httpClient.post<SmsSettings>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
  }


  update(_setting: SmsSettings): Observable<SmsSettings> {
    return this.httpClient.put<SmsSettings>(this.API_URL + _setting._id, JSON.stringify(_setting), this.headerOptions);
  }


  delete(id: string): Observable<SmsSettings> {
    return this.httpClient.delete<SmsSettings>(this.API_URL + id);
  }


  search(searchTerms): Observable<SmsSettings[]> {
    return this.httpClient.post<SmsSettings[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }
}
