
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/internal/Observable';

import { cacheable } from '@app/utils/rxjs-functions';

import { ExpiringCache, User } from '../models';

import { AuthService } from './auth.service';
import { SharedUtilsService } from './shared-utils.service';
import { GenericModuleService } from './generic-module.service';
import { SsModuleService } from './ss-module.service';
import { RoleService } from './role.service';
import { EmailAddressService } from './email-address.service';
import { map } from 'rxjs/internal/operators/map';

export interface UserApi {
  items: User[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUserBeingViewed: User = null;

  public passwordPattern = "^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=?!*]).*$";
  public passwordPatternDescription = "Not valid, passwords must be at least 6 characters AND contain at least 1 lowercase, 1 uppercase AND also at least one character from the set @#$%^&+=";

  usersStorage: ExpiringCache = new ExpiringCache();

  private API_URL = '/api/users/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  public globalTeam = null;

  public userTypes = ['Admin', 'Standard', 'Dashboard', 'Provider'];
  public accessTypes = ['Admin', 'Edit', 'View', 'TST', 'TST Admin'];
  public recruiterLevels = ['Junior', 'Associate', 'Senior', 'Executive'];

  public currentSearch = {
    params: null,
    results: null
  };

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private _sharedUtilsService: SharedUtilsService,
    private _emailAddressService: EmailAddressService,
    private _authService: AuthService,
    private _moduleService: SsModuleService,
    private _roleService: RoleService,
    private _genericModuleService: GenericModuleService
  ) { }

  setCurrentUserBeingViewed(_usr: User): void {
    this.currentUserBeingViewed = _usr;
  }


  getCurrentUserBeingViewed(): User {
    return this.currentUserBeingViewed;
  }


  getCurrentlyLoggedInUser(): User {
    return this._authService.getCurrentlyLoggedInUser();
  }  


  getCurrentUsersBaseRoute() {
    const currentUser = this._authService.getCurrentlyLoggedInUser();
    return this.getRouteForUser(currentUser.userType);
  }


  getGlobalTeam() {
    return new Promise(async(resolve) => {
      const globalTeam = await this._genericModuleService.getGlobalTeam();
      resolve(globalTeam);
    });
  }


  createUserForProvider(_provider): Promise<User> {
    return new Promise(async(resolve) => {
      // console.log('Creating user from provider: ', _provider);
      const providerEmail = _provider.email;
      // console.log('Provider email: ', providerEmail);

      let savedUser = null;

      // console.log('_provider to create user from: ', _provider);

      if (!providerEmail) {
        console.log('NO EMAIL ADDRESS DETECTED ON PROVIDER!!!');
        resolve(null);
      } else {
        const teamModule = await this._moduleService.waitForModule('teams');

        const genericSearchData = {
          name: 'teams',
          schema: teamModule.customSchema,
          searchTerms: {},
          relationshipsNeeded: null
        };

        const teams = await this._genericModuleService.search(genericSearchData).toPromise();
        const providerTeam = teams.find(_t => _t.name === 'Provider');

        const roles = await this._roleService.search({name: 'Provider'}).toPromise();
        const providerRole = roles.find(_r => _r.name === 'Provider');

        // console.log('providerTeam: ', providerTeam);
        // console.log('providerRole: ', providerRole);

        const providerUser = new User();
        providerUser.email = providerEmail;
        providerUser.active = true;
        providerUser.password = '';
        providerUser.userType = 'Provider';
        providerUser.first_name = _provider.first_name;
        providerUser.last_name = _provider.last_name;
        providerUser.teams = (providerTeam && providerTeam._id) ? [providerTeam._id] : null; // needs to be provider team
        providerUser.roles = (providerRole && providerRole._id) ? [providerRole._id] : null; // [providerRole._id];

        savedUser = await this.create(providerUser).toPromise();
        // await this.activate(savedUser).toPromise();
        await this.providerAccountRequest(savedUser).toPromise();

        resolve(savedUser);
      }
    });
  }


  updateUserFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<User[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<User[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  getAll(): Observable<User[]> {
    // smarter than just going to api every time. Getting them all, so just cache for use in dropdowns all over
    if (this._sharedUtilsService.isStorageValid(this.usersStorage)) {
      // console.log('Saved an API call for users. Retreived from cache.');
      return this.usersStorage.itemCache;
    }

    this.usersStorage = this._sharedUtilsService.updateStorageExpiration(this.usersStorage);
    // console.log('Set users storage to: ', this.usersStorage);
    return this.usersStorage.itemCache = cacheable<any>(this.httpClient.get<User[]>(this.API_URL));
  }


  getAllWithoutCache(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.API_URL);
  }


  create(user: User): Observable<User> {
    return this.httpClient.post<User>(this.API_URL, JSON.stringify(user), this.headerOptions);
  }


  activate(user: User): Observable<User> {
    return this.httpClient.post<User>(this.API_URL + 'activate', JSON.stringify(user), this.headerOptions);
  }
  

  createTempUserAccount(user: User): Observable<User> {
    return this.httpClient.post<User>(this.API_URL + 'createTempUserAccount', JSON.stringify(user), this.headerOptions);
  }


  checkUserSession(): Observable<any> {
    return this.httpClient.get(this.API_URL + 'user-session');
  }


  updateRoles(_roleUpdateReq): Observable<string[]> {
    return this.httpClient.post<string[]>(this.API_URL + 'update-roles', JSON.stringify(_roleUpdateReq), this.headerOptions);
  }


  updateTeams(_teamUpdateReq): Observable<string[]> {
    return this.httpClient.post<string[]>(this.API_URL + 'update-teams', JSON.stringify(_teamUpdateReq), this.headerOptions);
  }


  register(user: User): Observable<string[]> {
    return this.httpClient.post<string[]>(this.API_URL + 'register', JSON.stringify(user), this.headerOptions);
  }


  providerAccountRequest(user): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'provider-account-invite', JSON.stringify(user), this.headerOptions);
  }


  passwordResetRequest(user): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'password-reset', JSON.stringify(user), this.headerOptions);
  }


  getUser(id: any): Observable<User> {
    return this.httpClient.get<User>(this.API_URL + id);
  }  


  delete(id: string): Observable<User> {
    return this.httpClient.put<User>(this.API_URL + 'mark-deleted/' + id, null, this.headerOptions);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = {ids: arrayOfIds};
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  checkEmail(email: string): Observable<User[]> {
    return this.httpClient.get<User[]>(this.API_URL + 'check-email/' + email);
  }   


  updateUser(user: User): Observable<User> {
    return this.httpClient.put<User>(this.API_URL + user._id, JSON.stringify(user), this.headerOptions);
  }


  completeRegistration(user: User): Observable<User> {
    return this.httpClient.post<User>(this.API_URL + 'completeRegistration', JSON.stringify(user), this.headerOptions);
  }


  tempLogin(user: User): Observable<User> {
    return this.httpClient.post<User>(this.API_URL + 'templogin', JSON.stringify(user), this.headerOptions);
  }


  search(searchTerms): Observable<User[]> {
    return this.httpClient.post<User[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  } 


  updateFieldsById(_id, fieldsToUpdate): Observable<User[]> {
    return this.httpClient.put<User[]>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }   


  login(user: User) {
    return this.httpClient.post<any>(this.API_URL + 'login', JSON.stringify(user), this.headerOptions);
  } 


  logLoginDate(userId: string): Observable<User> {
    return this.httpClient.put<User>(this.API_URL + userId + '/log-login-date/', this.headerOptions);
  } 


  getUserRolesAndAccess(_userId: string): Observable<User> {
    return this.httpClient.get<User>(this.API_URL + _userId + '/complete-roles-info');
  } 


  logoutWithoutRemovingUser(): Observable<any> {
    return this.httpClient.get(this.API_URL + 'logout', this.headerOptions);    
  }


  logout() {
    return this.httpClient.get(this.API_URL + 'logout', this.headerOptions).pipe(
      map((res) => {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');        
        // res.json();
      })
    );    
  }  


  getUsersByType(userType: string): Observable<User[]> {
    return this.httpClient.get<User[]>(this.API_URL + 'type/' + userType);
  }


  getUserToAssignTask(): Observable<User[]> {
    return this.httpClient.get<User[]>(this.API_URL + 'user-to-assign-change-requests');
  }


  getUsersForRole(_roleId: string): Observable<User[]> {
    return this.httpClient.get<User[]>(this.API_URL + 'role/' + _roleId);
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  selectAllSearch(searchTerms): Observable<User[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<User[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getUserCustomDatasource(searchParams, sortField = 'last_name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<UserApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<UserApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }


  userFormGroup(formData: User) {
    return this.formBuilder.group({
        userId: [formData.userId],
        email: [formData.email, Validators.required],
        password: [formData.password],
        username: [formData.username],
        userType: [formData.userType, Validators.required],
        recruiter_level: [formData.recruiter_level],
        sendgrid_approved: [formData.sendgrid_approved],
        assign_leads: [formData.assign_leads],
        pinning_posts_enabled: [formData.pinning_posts_enabled],
        client: [formData.client],
        first_name: [formData.first_name, Validators.required],
        last_name: [formData.last_name, Validators.required],
        phone: [formData.phone],
        phone_ext: [formData.phone_ext],
        other_phone: [formData.other_phone],
        street: [formData.street],
        city: [formData.city],
        state: [formData.state],
        zip: [formData.zip],
        hire_date: [formData.hire_date],
        birth_date: [formData.birth_date],
        active: [formData.active]
    });
  }  
  

  isSuperAdmin(_user) {
    let isSuper = false;

    if (_user.userType === 'System Admin') {
      isSuper = true;
    }

    return isSuper;
  }  
  
  
  checkForAdminPrivileges(_user) {
    let hasPrivelages = false;

    if (_user.userType === 'System Admin') {
      hasPrivelages = true;
    }

    return hasPrivelages;
  }    


  getRouteForUser(userType) {
    let userPortal = '/app';

    if (userType === 'System Admin') {
      userPortal = '/admin';
    } else if (userType === 'Provider') {
      userPortal = '/provider';
    }

    return userPortal;
  }


  goToUserProfile(_user) {
    const currentUserLoggedIn = this.getCurrentlyLoggedInUser();
    let builtRoute = '';

    if (_user != undefined && _user._id != undefined) {
      if ((currentUserLoggedIn != undefined && currentUserLoggedIn._id != undefined) && currentUserLoggedIn._id === _user._id) {
        builtRoute = '/app/user/profile';
      } else {
        this.setCurrentUserBeingViewed(_user);
        builtRoute = '/app/user/profile/' + _user._id;
      }

      this._sharedUtilsService.reloadComponentByRoute(builtRoute);
    }
  }
}