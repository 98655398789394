import { Component } from '@angular/core';

import { ThemeService } from '@app/services/theme.service';
import { NavigationService } from '@app/services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public selectedTheme: string;

  constructor(
    private _navigationService: NavigationService,
    private themeService: ThemeService
  ) {
    this.selectedTheme = themeService.selectedTheme;
    console.log('Selected Theme:', this.selectedTheme);
    
    this._navigationService.startSaveHistory();
  }

  ngOnInit() {
    this.themeService.listenForThemeChange().subscribe(_updatedTheme => this.selectedTheme = _updatedTheme);
  }
}