import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { PtoRequest } from '@app/models';

export interface PtoRequestApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class PtoRequestService {
  private currentRequest: PtoRequest = null;

  private triggerRequestTimeOffFormObs$ = new Subject<any>();
  private requestChangedObs$ = new Subject<any>();

  public currentSearch = {
    params: null,
    results: null
  };

  // allows you to do things like trigger a dialog after loading page
  public currentRequestSource = {
    showDialogOnLoad: null,
    currentDatesSelected: null
  };

  private API_URL = '/api/pto-requests/';
  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient
  ) { }

  setCurrent(_request): void {
    this.currentRequest = _request;
  }

  getCurrent() {
    return this.currentRequest;
  }


  listenForRequestTimeOffFormTrigger() {
    return this.triggerRequestTimeOffFormObs$;
  }


  announceRequestTimeOffFormTrigger() {
      this.triggerRequestTimeOffFormObs$.next(null);
  }


  listenForRequestChange() {
    return this.requestChangedObs$;
  }


  announceRequestChange() {
      this.requestChangedObs$.next(null);
  }


  create(_request: PtoRequest): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_request), this.headerOptions);
  }


  // updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
  //   const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
  //   return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields/', JSON.stringify(updateRequest), this.headerOptions);
  // }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<PtoRequestApi> {
    return this.httpClient.put<PtoRequestApi>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_request: PtoRequest): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _request._id, JSON.stringify(_request), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  normalSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'normal-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getRequest(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  selectAllSearch(searchTerms): Observable<PtoRequestApi> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<PtoRequestApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<PtoRequestApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<PtoRequestApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  }
}