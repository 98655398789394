import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { SmsSignature } from '../models/smsSignature';

@Injectable({ providedIn: 'root' })
export class SmsSignatureService {
  private API_URL = '/api/sms-signatures/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private currentSignature: SmsSignature;
  private signaturesObs$ = new Subject<any>();

  constructor(
    private httpClient: HttpClient
  ) { }


  setCurrent(_signature: SmsSignature): void {
    this.currentSignature = _signature;
  }


  getCurrent(): SmsSignature {
    return this.currentSignature;
  }


  listenForSignatureChange() {
    return this.signaturesObs$;
  }


  announceSignatureChange(_signatures) {
    this.signaturesObs$.next(_signatures);
  }


  get(_id: string): Observable<SmsSignature> {
    return this.httpClient.get<SmsSignature>(this.API_URL + _id);
  }


  getByUser(_userId: string): Observable<SmsSignature[]> {
    return this.httpClient.get<SmsSignature[]>(this.API_URL + 'by-user/' + _userId);
  }


  getBySetting(_settingId: string): Observable<SmsSignature[]> {
    return this.httpClient.get<SmsSignature[]>(this.API_URL + 'by-setting/' + _settingId);
  }


  getAll(): Observable<SmsSignature[]> {
    return this.httpClient.get<SmsSignature[]>(this.API_URL);
  }


  create(_signature): Observable<SmsSignature> {
    return this.httpClient.post<SmsSignature>(this.API_URL, JSON.stringify(_signature), this.headerOptions);
  }


  update(_signature: SmsSignature): Observable<SmsSignature> {
    return this.httpClient.put<SmsSignature>(this.API_URL + _signature._id, JSON.stringify(_signature), this.headerOptions);
  }


  delete(id: string): Observable<SmsSignature> {
    return this.httpClient.delete<SmsSignature>(this.API_URL + id);
  }


  search(searchTerms): Observable<SmsSignature[]> {
    return this.httpClient.post<SmsSignature[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }
}