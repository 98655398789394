export class Activity {
    _id: string;
    activityType: string;
    status: string;
    relatedRecord: { // lead, account, etc.
        recordType: string,
        recordId: string
    };
    relatedRecords: [{ // lead, account, etc.
        recordType: string,
        recordId: string
    }];
    relatedModule: { // email, sms, call, etc.
        moduleType: string,
        moduleRecordId: string
    };
    campaign: any;
    call: any;
    email: any;
    note: any;
    sms: any;
    lead: any;
    sub: any;
    account: any;
    contact: any;
    relatedIds: any;
    no_deep_relates: boolean; // search term
    relatedIdsMulti: any;
    messageHtml: any;
    created_by: any;
    modified_by: any;
    assigned_to: any;
    deleted: boolean;  
    createdAt: Date;
    updatedAt: Date;
    hasSearchTerms: any;
    dateCreatedRange: any;
    dateCreated;
    dateCreatedEnd;
    anyTerm: any;
    populatedRelatedRecord;
    populatedRelatedModule;
    relatedModuleContent; // For activity search
    leadStatus: string; // For activity search
}