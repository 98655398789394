import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({ providedIn: 'root' })

export class ThemeService {
    private currentTheme;

    private themeObs$ = new Subject<any>();

    selectedTheme: any;
    themes: any[];

    constructor() {
        let currentTheme = localStorage.getItem('theme');

        if (!currentTheme) { currentTheme = 'light'; }

        this.selectedTheme = currentTheme;

        this.themes = [
            'default',
            'matts',
            'blue-and-teal',
            'bluecoral',
            'candy',
            'dark',
            'fire',
            'indigo-pink',
            'light',
            'pink-bluegrey',
            'pistachio',
            'justins'
        ];
    }


    listenForThemeChange() {
        return this.themeObs$;
    }

    announceThemeChange(_theme) {
        localStorage.setItem('theme', _theme);
        this.themeObs$.next(_theme);
    }     


    setCurrentBeingViewed(_theme): void {
        this.currentTheme = _theme;
    }

    getCurrentBeingViewed() {
        return this.currentTheme;
    }


    change(theme) {
        this.selectedTheme.name = theme;
        localStorage.setItem('theme', theme);
    }
    
    
    getCurrentTheme() {
        return localStorage.getItem('theme');
    }
}
