import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { Lead } from '@app/models/lead';
import { Activity } from '@app/models/activity';

import { DupLeadDialogComponent } from '@app/modules/leads/dup-lead-dialog/dup-lead-dialog.component';
import { DuplicationMergerDialogComponent } from '@app/modules/module-tools/duplication-merger-dialog/duplication-merger-dialog.component';
import { AddressVerificationDialogComponent } from '@app/modules/address-verifier/address-verification-dialog/address-verification-dialog.component';

import { GenericModuleService, SharedUtilsService, SsModuleService, TableService } from '.';

import { MergeService } from './merge.service';
import { ActivityService } from './activity.service';
import { EmailAddressService } from './email-address.service';
import { AddressVerificationService } from './address-verification.service';

export interface LeadApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class LeadService {
  private leadObs$ = new Subject<Lead>();
  private currentLead;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/leads/';
  private CAMPAIGNS_URL = '/api/campaigns/';

  private headerOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(
    private httpClient: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private _dialog: MatDialog,
    private _moduleService: SsModuleService,
    private _mergeService: MergeService,
    private _activityService: ActivityService,
    private _genericService: GenericModuleService,
    private _emailAddressService: EmailAddressService,
    private _addressVerificationService: AddressVerificationService,
    private _tableService: TableService,
    private _sharedUtilsService: SharedUtilsService
  ) { }

  setCurrent(_lead): void {
    this.currentLead = _lead;
  }


  getCurrent() {
    return this.currentLead;
  }


  listenForLeadChange() {
    return this.leadObs$;
  }


  announceLeadChange(_lead: Lead) {
      this.leadObs$.next(_lead);
  }


  create(_lead): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_lead), this.headerOptions);
  }


  updateLeadStatus(_leadUpdateReq): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'update-lead-status', JSON.stringify(_leadUpdateReq), this.headerOptions);
  }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate, _userUpdating = null): Observable<any[]> {
    const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate, user: _userUpdating};
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<LeadApi> {
    return this.httpClient.put<LeadApi>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_lead): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _lead._id, JSON.stringify(_lead), this.headerOptions);
  }


  updateFavorites(_lead): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _lead._id, JSON.stringify(_lead), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  checkForOwnership(_ownerParams): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'ownership-check', JSON.stringify(_ownerParams), this.headerOptions);
  }


  basicSearch(searchTerms): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'basic-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getLead(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  dupCheck(_lead): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'dup-check', JSON.stringify(_lead), this.headerOptions);
  }


  getLeadsMatchingJob(_job): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'matching-leads', JSON.stringify(_job), this.headerOptions);
  }


  getLeadsCampaigns(_leadId): Observable<any[]> {
    return this.httpClient.get<any[]>(this.CAMPAIGNS_URL + 'leads/' + _leadId + '/campaigns');
  }


  performWorkflowAutomationMatchingLeads(_jobDetails): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + 'leads-workflow', JSON.stringify(_jobDetails), this.headerOptions);
  }


  matchJobsToLead(_lead): Observable<any[]> {
    return this.httpClient.post<any[]>(this.API_URL + _lead._id + '/match-jobs', JSON.stringify(_lead), this.headerOptions);
  }


  getMatchingJobs(_leadId: string): Observable<any[]> {
    // find all jobs for this leads zip in a given radius
    return this.httpClient.get<any>(this.API_URL + _leadId + '/matching-jobs');
  }


  getAppliedJobs(_leadId: string): Observable<any[]> {
    // find all jobs for this leads zip in a given radius
    return this.httpClient.get<any>(this.API_URL + _leadId + '/applied-jobs');
  }


  getMatchingAccounts(_leadId: string): Observable<any[]> {
    // find all jobs for this leads zip in a given radius
    return this.httpClient.get<any>(this.API_URL + _leadId + '/matching-accounts');
  }


  doRadiusSearchForJobs(_leadZip, _radius): Observable<any[]> {
    // find all jobs for this leads zip in a given radius
    return this.httpClient.get<any>(this.API_URL + 'jobs-in-radius/' + _leadZip + '/' + _radius);
  }


  leadJobPrimaryAddressSearch(_searchTerms) {
    return this.httpClient.post<any[]>(this.API_URL + 'primary-address-radius-search', JSON.stringify(_searchTerms), this.headerOptions);
  }


  leadInterestedLocationJobSearch(_searchTerms) {
    return this.httpClient.post<any[]>(this.API_URL + 'interested-location-job-search', JSON.stringify(_searchTerms), this.headerOptions);
  }


  leadAllJobsRadiusSearch(_searchTerms) {
    return this.httpClient.post<any[]>(this.API_URL + 'radius-search', JSON.stringify(_searchTerms), this.headerOptions);
  }


  selectAllSearch(searchOptions): Observable<LeadApi> {
    searchOptions.searchTerms['selectAll'] = true;
    searchOptions.searchTerms['selectNumberOfRecords'] = false;
    searchOptions.searchTerms['idsOnly'] = false; // Incase it is set on searchTerms.
    return this.httpClient.post<LeadApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchOptions), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    searchParams['idsOnly'] = false; // For only returning basic lightweight version of results if we don't need all fields.
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getLeadCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<LeadApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;
    searchParams['idsOnly'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<LeadApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
  } 


  leadFormGroup(formData) {
    return this.formBuilder.group({
      name: [formData.name, Validators.required]
    });
  }


  confirmDupCreation(_duplicatesDetected): Promise<boolean> {
    return new Promise(async (resolve) => {
      const dialogRef = this._dialog.open(DupLeadDialogComponent, {});

      const instance = dialogRef.componentInstance;
      instance.items = _duplicatesDetected;

      const _createWithMergeRes = await lastValueFrom(dialogRef.afterClosed());
      resolve(_createWithMergeRes);
    });
  }


  showMergeDialog(_leadModule) {
    return new Promise(async (resolve) => {
      const leadModule = this._moduleService.getFullModuleByName('leads');
      const dialogRef = this._dialog.open(DuplicationMergerDialogComponent, { disableClose: true, panelClass: 'user-search' });

      const instance = dialogRef.componentInstance;
      instance.modulePassedIn = leadModule;

      const _mergeResults = await lastValueFrom(dialogRef.afterClosed());
      const _mergedLead = await this.processMerge(_mergeResults, leadModule);

      resolve(_mergedLead);
    });
  }


  createActivityRecord(_lead) {
    return new Promise(async(resolve, reject) => {
      const activity = new Activity();
      activity.activityType = 'Merge';
      activity.relatedRecord = {recordType: 'leads', recordId: _lead._id};
      activity.relatedRecords = [{recordType: 'leads', recordId: _lead._id}];
      // activity.relatedModule = {moduleType: 'message', moduleRecordId: _message._id};
      activity.created_by = (_lead.created_by && _lead.created_by._id) ? _lead.created_by._id : _lead.created_by;
      activity.modified_by = (_lead.modified_by && _lead.modified_by._id) ? _lead.modified_by._id : _lead.modified_by;
      activity.assigned_to = (_lead.assigned_to && _lead.assigned_to._id) ? _lead.assigned_to._id : _lead.assigned_to;

      const savedActivity = await lastValueFrom(this._activityService.create(activity));

      resolve(savedActivity);
    });
  }


  processMerge(_mergeRequest, _module) {
    return new Promise(async (resolve) => {
      let mergedLead = null;

      if (_mergeRequest) {
        console.log('_mergeRequest: ', _mergeRequest);
        // console.log('Will update email address for: ', _mergeRequest.primaryRecord._id);

        mergedLead = await lastValueFrom(this.update(_mergeRequest.primaryRecord));
        // if email address was updated, add that as well

        // create merge activity record here...


        await this.createActivityRecord(_mergeRequest.primaryRecord);

        // console.log('Email Request: ', _mergeRequest.primaryRecord.email_addresses);

        // const _emailAddress = (_emailAddressObj && !_emailAddressObj.module_type && _emailAddressObj.email_address) ? _emailAddressObj : _emailAddressObj.email_address;

        if (_mergeRequest.mergedEmail && _mergeRequest.primaryRecord.email_addresses && _mergeRequest.primaryRecord.email_addresses.length) {
          const emailAddressesToUpdate = _mergeRequest.primaryRecord.email_addresses;

          const emailUpdateReq$ = await this._emailAddressService.updateAllEmailRecords('leads', _mergeRequest.primaryRecord._id, emailAddressesToUpdate);
          await lastValueFrom(emailUpdateReq$);
        }

        _mergeRequest.recordsToDelete.forEach(async(_rd) => {
          const _mergeCheck$ = await this._mergeService.mergeRequest('leads', _rd._id, _mergeRequest.primaryRecord._id);
          await lastValueFrom(_mergeCheck$);
        });

        // delete the deletedRecords
        const deleteReq$ = await this._genericService.markDeletedByIdArray({ name: 'leads', schema: _module.customSchema }, _mergeRequest.recordsToDelete);
        await lastValueFrom(deleteReq$);

        this._sharedUtilsService.showNotification("Leads have been merged and duplicates removed");
      }

      resolve(mergedLead);
    });
  }


  sharedLeadUpdated(_modulePassedIn, _updatedLead): Promise<any> {
    return new Promise(async (resolve) => {
      try {
        // console.log('Updated LEAD to create from: ', _updatedLead);
        const uneditedLead = {..._updatedLead};
        _updatedLead.name = _updatedLead.first_name + ' ' + _updatedLead.last_name;

        // check for dups here...
        this._sharedUtilsService.showNotification("Checking for duplicate leads");

        const allDuplicateRecords = await lastValueFrom(this.dupCheck(_updatedLead));
        const duplicateRecords = allDuplicateRecords.filter(_r => _r._id != _updatedLead._id);
        
        let proceedWithCreation = true;

        if (duplicateRecords && duplicateRecords.length) {
          const proceedAndDoMerge = await this.confirmDupCreation(duplicateRecords);
          // console.log('proceedAndDoMerge: ', proceedAndDoMerge);

          if (proceedAndDoMerge == false) {
            // just create the record. No merge check
            proceedWithCreation = true;
          } else if (proceedAndDoMerge == null) {
            // console.log('You said to abandon creation. Good move.');
            proceedWithCreation = false;
            resolve(null);
          } else {
            const duplicateRecordsWithPrimary = [];

            // if it doesn't exist, push it
            if (!allDuplicateRecords.find(_r => _r._id === _updatedLead._id)) {
              allDuplicateRecords.push(_updatedLead);
            }

            allDuplicateRecords.sort((a, b) => new Date(b['updatedAt']).getTime() - new Date(a['updatedAt']).getTime()).forEach(_r => duplicateRecordsWithPrimary.push(_r));
            this._tableService.resetSelectedRows();
            this._tableService.setResults(duplicateRecordsWithPrimary);    
            _updatedLead = await this.showMergeDialog(_modulePassedIn);
          }
        }

        if (!_updatedLead) _updatedLead = uneditedLead;

        // console.log('_updatedLead: ', _updatedLead);

        // Had to pull out _workflowToolsService.performWorkflowChecks call due to circular issues, so make sure to call in calling function
        const savedEmails = await lastValueFrom(this._emailAddressService.updateAllEmailRecords(_modulePassedIn.name, _updatedLead._id, _updatedLead['email_addresses']));

        let _newUpdatedLead = {..._updatedLead};

        // Only do this if the address has at least one param filled out.
        // if (this.isAddressWithParams(_updatedLead?.current_address)) {
        //   const addressToConfirm = await this.getSuggestedAddress(_updatedLead.current_address);
        //   // console.log('Address to confirm: ', addressToConfirm);

        //   if (addressToConfirm) _updatedLead.current_address = addressToConfirm;
        // }

        if (proceedWithCreation) {
          this._sharedUtilsService.showNotification("Saving updates");
          // console.log('No merge was performed. Do update here.');
          _newUpdatedLead = await lastValueFrom(this.update(_updatedLead));
        }

        await lastValueFrom(this.matchJobsToLead(_updatedLead));
        _newUpdatedLead['email_addresses'] = savedEmails;

        resolve(_newUpdatedLead);
      } catch (_err) {
        console.log('Error creating lead: ', _err);
        resolve(null);
      }
    });
  }


  getSuggestedAddress(_address): Promise<any> {
    return new Promise(async (resolve) => {
      try {
        // console.log('Get suggested address for: ', _address);
        const addressesSuggested = await lastValueFrom(this._addressVerificationService.checkAddress(_address));
        // console.log('addressesSuggested: ', addressesSuggested);

        let triggerDialog: boolean = true;

        // Perform condition to see if dialog should be triggered.
        if (!addressesSuggested || !addressesSuggested.length) {
          // Suggested Addresses where null or had no length. So just resolve the passed one.
          triggerDialog = false;
        } else if (addressesSuggested.length === 1) {
          // There was only one address returned. So make sure its not the same as one set.
          const addressToCompare = addressesSuggested[0];
          triggerDialog = (!this.addressesEqual(_address, addressToCompare)) ? true : false;
        }

        // If not triggerDialog just resolve the passed address.
        if (triggerDialog) {
          const dialogRef = this._dialog.open(AddressVerificationDialogComponent, {hasBackdrop: true, disableClose: true});
          const instance = dialogRef.componentInstance;
          instance.currentAddress = _address;
          instance.addressesSuggested = addressesSuggested;

          const verifiedAddress = await lastValueFrom(dialogRef.afterClosed());
          resolve(verifiedAddress);
        } else {
          resolve(_address);
        }
      } catch (_err) {
        console.log('Error getting suggested address: ', _err);
        resolve(null);
      }
    });
  }


  isAddressWithParams(_address) {
    let hasParams: boolean = false;

    if ((_address?.street && _address?.street !== '') || 
      (_address?.city && _address?.city !== '') || 
      (_address?.state && _address?.state !== '') || 
      (_address?.zip && _address?.zip !== '')) hasParams = true;

    return hasParams;
  }


  addressesEqual(obj1, obj2) {
    // Check if both objects are null or undefined
    if (obj1 == undefined || obj2 == undefined) return obj1 == obj2;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false; // Different number of properties

    for (let key of keys1) {
      if (!(key in obj2) || obj1[key] !== obj2[key]) return false;
    }

    return true;
  }


  sharedLeadCreated(_modulePassedIn, _newLead): Promise<any> {
    return new Promise(async (resolve) => {
      try {
        // console.log('NEW LEAD to create from: ', _newLead);
        _newLead.name = _newLead.first_name + ' ' + _newLead.last_name;

        this._sharedUtilsService.showNotification("Checking for duplicate leads");

        // check for dups here...
        const duplicateRecords = await lastValueFrom(this.dupCheck(_newLead));
        // console.log('Dup Record Check: ', duplicateRecords);

        let proceedWithCreation = true;

        // Only do this if the address has at least one param filled out.
        if (this.isAddressWithParams(_newLead?.current_address)) {
          this._sharedUtilsService.showNotification("Checking for addresses");
          const addressToConfirm = await this.getSuggestedAddress(_newLead.current_address);
          // console.log('Address to confirm: ', addressToConfirm);

          if (addressToConfirm) _newLead.current_address = addressToConfirm;
        }

        this._sharedUtilsService.showNotification("Saving Lead");
        const _newLeadCreated = await lastValueFrom(this.create(_newLead));

        if (duplicateRecords && duplicateRecords.length) {
          const proceedAndDoMerge = await this.confirmDupCreation(duplicateRecords);
          // console.log('proceedAndDoMerge: ', proceedAndDoMerge);

          if (proceedAndDoMerge == false) {
            // just create the record. No merge check
            proceedWithCreation = true;
          } else if (proceedAndDoMerge == null) {
            // console.log('You said to abandon creation. Good move.');
            proceedWithCreation = false;

            resolve(null);
          } else {
            const duplicateRecordsWithPrimary = [_newLeadCreated];
            duplicateRecords.forEach(_r => duplicateRecordsWithPrimary.push(_r));
    
            this._tableService.setResults(duplicateRecordsWithPrimary);
    
            // console.log('Selected Rows: ', this._tableService.selectedRows.selected);
    
            await this.showMergeDialog(_modulePassedIn);
          }
        }

        // Had to pull out _workflowToolsService.performWorkflowChecks call due to circular issues, so make sure to call in calling function
        // console.log('_newLead (Should have them here): ', _newLead);
        // console.log('_newLeadCreated (missing email addresses. Newly created): ', _newLeadCreated);

        if (_newLead['email_addresses'] && _newLead['email_addresses'].length) {
          this._sharedUtilsService.showNotification("Saving Leads Email Addresses");
          await lastValueFrom(this._emailAddressService.updateAllEmailRecords(_modulePassedIn.name, _newLeadCreated._id, _newLead['email_addresses']));
        }

        _newLead._id = _newLeadCreated['_id'];

        this._sharedUtilsService.showNotification("Matching to Jobs");
        await lastValueFrom(this.matchJobsToLead(_newLead));

        // console.log('Matching complete. Should redirect to new lead?');
        resolve(_newLeadCreated);
      } catch (_err) {
        console.log('Error creating lead: ', _err);
        resolve(null);
      }
    });
  }
}