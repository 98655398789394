import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import * as moment from 'moment';
import { TimeSheet } from '../models/timeSheet';

export interface TimeSheetApi {
    items: TimeSheet[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class TimeSheetService {
    private API_URL = '/api/timeSheets/';
    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(
        private httpClient: HttpClient
    ) { }


    getAll(): Observable<TimeSheetApi> {
        return this.httpClient.get<TimeSheetApi>(this.API_URL);
    }


    get(_id: string): Observable<TimeSheet> {
        return this.httpClient.get<TimeSheet>(this.API_URL + _id);
    }


    create(_timeSheet): Observable<TimeSheet> {
        return this.httpClient.post<TimeSheet>(this.API_URL, JSON.stringify(_timeSheet), this.headerOptions);
    }


    update(_timeSheet: TimeSheet): Observable<TimeSheet> {
        return this.httpClient.put<TimeSheet>(this.API_URL + _timeSheet._id, JSON.stringify(_timeSheet), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'date', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<TimeSheetApi> {
        searchParams['selectAll'] = false;
        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<TimeSheetApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions); 
    }


    search(searchTerms): Observable<TimeSheetApi> {
        return this.httpClient.post<TimeSheetApi>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    searchForUsersWeeklyTimesheet(searchTerms): Observable<TimeSheet> {
        return this.httpClient.post<TimeSheet>(this.API_URL + 'usersWeeklyTimesheet', JSON.stringify(searchTerms), this.headerOptions);
    }


    delete(id: String): Observable<any> {
        return this.httpClient.delete(this.API_URL + id);
    }


    // Get the total hours in a passed TimeSheet's timeEntries array
    getTotalHours(_timeSheet: TimeSheet) {
        const durations = [];
        _timeSheet.timeEntries.forEach(_entry => {
        if (_entry.start_time && _entry.end_time)  {
            let duration = moment.duration(moment(_entry.end_time , "HH:mm").diff(moment(_entry.start_time , "HH:mm")))
            let durationAsString = duration.get('hours') + ':' + duration.get('minutes');
            durations.push(durationAsString);
        }
        });
        return moment.utc(durations.slice(1).reduce((prev, cur) => moment.duration(cur).add(prev), moment.duration(durations[0])).asMilliseconds()).format("H:mm");
    }
}
