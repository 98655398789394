<div class="duplication-merger-dialog">

  <div class="dialog-title">
    <h3>Deduplicate {{ modulePassedIn?.name | titlecase }}</h3>

    <div class="desc">
      The first record you choose will be used as the primary record, unless you change it. All selected values from the duplicate 
      records along with any activities, documents, subs, etc. will be merged with the master record. The master record will retain the creation date.
    </div>
  </div>

  <div class="dialog-body">
    <div class="loading-shaded-spinner" *ngIf="retrievingRecords">
      <mat-spinner></mat-spinner>
    </div>

    <div class="merge-wrapper" *ngIf="!retrievingRecords">


      <table class="record-table">
        <thead>
          <th>&nbsp;</th>
          <th>Primary Record - Record {{ masterRecordIndex + 1 }}</th>
          <th *ngFor="let _record of chosenRecords; let i = index;">
            <div class="record-title default-flex space-between">
              <mat-radio-button matTooltip="Set as Primary Record" color="primary" [checked]="masterRecordIndex === i" (change)="setPrimaryRecord(i)">Record {{ i + 1 }}</mat-radio-button>
  
              <div class="remove-record" (click)="removeRecord(i)">
                <i class="material-icons">clear</i>
              </div>
            </div>            
          </th>
        </thead>

        <tr *ngFor="let _field of fields">
          <td>
            <div class="field-text field-col-text" [matTooltip]="_field.label">{{ _field.label }}</div>
          </td>

          <td>
            <div class="field-text" *ngIf="primaryRecord">
              <field-type-edit [fieldPassedIn]="_field" [recordPassedIn]="primaryRecord" [hideLabels]="true"></field-type-edit>
            </div>            
          </td>

          <td *ngFor="let _record of chosenRecords; let i = index;" class="field">
            <div class="field-text hover-border" *ngIf="fieldToIndexMapping" matTooltip="Use This Value" (click)="setPrimaryFromField(i, _field.fieldName)">
              <field-type-edit [fieldPassedIn]="_field" [recordPassedIn]="_record" [hideLabels]="true" [disabled]="true"></field-type-edit>
            </div>            
          </td>
        </tr>
      </table>

    </div>
  </div>

  <div class="action-buttons">
    <button mat-stroked-button mat-dialog-close (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="saveMerge()">Save Merge</button>
  </div>
</div>